import { useTranslation } from 'react-i18next';
import { getTrafficLightBg } from 'utils/liveUtils';

const StateIndicator = ({ data, stage }) => {
  const { t } = useTranslation('glossary');
  const state = data[stage];
  const bgColor = getTrafficLightBg(state);
  return (
    <div className='d-flex align-items-center'>
      <span className={`p-1 rounded-circle bg-${bgColor}`} />
      <p className='m-0 ms-2 color-high fw-normal text-capitalize text-sm'>
        {t(stage)}
      </p>
    </div>
  );
};

export default StateIndicator;
