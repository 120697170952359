import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

// import { renderIcon } from 'utils/liveUtils';
import { actionTypes } from 'constants/live';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';

const LiveModal = ({ name, onConfirm, isDanger, ...props }) => {
  const { subtitles, count, onHide } = { ...props };
  const { theme } = useSelector(({ user }) => user.settings);
  const { restart, stop } = actionTypes;

  const renderTitle = name => {
    switch (name) {
      default:
      case restart:
        return 'modal_restart_title';
      case stop:
        return 'modal_stop_title';
      case 'stopAll':
        return count > 1 ? 'modal_stop_title_plural' : 'modal_stop_title';
    }
  };

  const renderText = name => {
    switch (name) {
      default:
      case restart:
        return 'alert_warning';
      case stop:
      case 'stopAll':
        return 'modal_stop_text';
    }
  };

  const title = renderTitle(name);
  const text = renderText(name);

  return (
    <Modal
      className={`text-center ${theme}`}
      contentClassName='p-4'
      {...props}
      centered
    >
      <Modal.Header className='flex-column'>
        {/* <span
          className={`nav-icon-size ${isDanger ? 'text-danger' : 'color-high'}`}
        >
          {renderIcon(isDanger ? 'warning' : name)}
        </span> */}
        <Modal.Title>
          <h1 className='h3 color-high'>
            <Trans i18nKey={title} count={count}>
              {title}
            </Trans>
          </h1>
          {subtitles?.map((subtitle, index) => (
            <h2 key={index} className='h5 color-primary lh-base m-0'>
              {`${subtitle}${index + 1 !== subtitles?.length ? ',' : ''}`}
            </h2>
          ))}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='color-medium py-4'
        style={{ whiteSpace: 'pre-line' }}
      >
        <Trans i18nKey={text}>{text}</Trans>
      </Modal.Body>

      <Modal.Footer className='d-grid gap-3 d-flex justify-content-center'>
        <EmptyButton onClick={onHide}>
          <Trans i18nKey='cancel'>cancel</Trans>
        </EmptyButton>
        <Button
          variant={isDanger ? 'danger' : 'warning'}
          className={isDanger ? 'text-white' : ''}
          onClick={onConfirm}
        >
          <Trans i18nKey='confirm'>confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LiveModal;
