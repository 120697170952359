import http from 'services/httpService';

const api = process.env.REACT_APP_COCKPIT_URL;

export function getUserBlueprints() {
  return http.get(`${api}/blueprints`);
}

export function getUserData() {
  return http.get(`${api}/user`);
}

export function updateUserData(data) {
  const user = { ...data };
  delete user.isAuthenticated;
  delete user.isFirstLogin;
  delete user.groups;
  delete user.blueprints;
  delete user.loading;
  delete user.error;
  delete user._persist;
  return http.put(`${api}/user`, user);
}
