import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useState, Fragment, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { parseRatePreset, removeCustomBitrate } from 'utils/utils';
import {
  mapToAllBundles,
  populateTemplateValues,
  removeNoValueFromPayload,
  parseNotification,
  setHttpAndNoOutputValues
} from 'utils/vodUtils';
import { FisheyeProjectionTypes } from 'constants/live';
import { VOD_URL_TYPES, PACKAGING_FORMAT_DEFAULT } from 'constants/vod';
import { getVodEncode, scheduleVodEncode } from 'store/vod/vodThunk';
import { VodStartFormConfigs as Manual } from 'constants/vodForm';

import { FormSection } from 'components/VodFormSection';
import OverviewSection from 'components/VodOverviewSection';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import CancelModal from 'components/CancelModal';
import Loader from 'components/Loader';
import BackIcon from 'remixicon-react/ArrowLeftLineIcon';

const RestartEncodePage = () => {
  dayjs.extend(duration);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { lng, encode_uuid } = useParams();

  const {
    vod_profiles: { sources, destinations },
    loading: userLoading
  } = useSelector(({ user }) => user);
  const { profile, encode, loading } = useSelector(({ vod }) => vod);

  const [data, setData] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);
  const [editSection, setEditSection] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timestamp] = useState(dayjs().format('YYYYMMDD_HHmmss'));

  const isTemplate = data?.template_hls;
  const schemaKey = isTemplate ? 'tempSchema' : 'typeSchema';

  const handleCancel = () => push(`/${lng}/vod`);

  const handleSave = values => {
    const encode = { ...values };

    if (isTemplate) {
      const {
        source: { multi_video }
      } = encode;
      encode.source.multi_video = multi_video.map(({ src_file, ...rest }) => ({
        ...rest,
        src_file: src_file.trim()
      }));
    } else {
      const {
        source: { ingest },
        output: { uuid, name, type },
        encoding_v3
      } = encode;
      const {
        video: { bundles }
      } = encoding_v3[0];

      encode.source.video.src_file = encode.source.video.src_file.trim();
      encode.source.ingest =
        ingest.uuid === 'http-pull'
          ? { uuid: ingest.uuid, type: ingest.uuid }
          : {
              ...[initialLocation.source, ...sources].find(
                ({ uuid }) => uuid === ingest.uuid
              )
            };
      encode.output = setHttpAndNoOutputValues(encode.output) || {
        uuid,
        name,
        type,
        ...[initialLocation.destination, ...destinations].find(
          ({ uuid }) => uuid === encode.output.uuid
        )
      };

      encode.encoding_v3[0].video.bundles = bundles.map(bundle =>
        removeCustomBitrate(bundle)
      );
    }
    encode['notification'] = parseNotification(values?.notification);

    setData(encode);
    setEditSection(null);
  };

  const handleRestart = () => {
    const payload = { ...data };

    if (!isTemplate) {
      const { encoding_v3, source } = payload;
      const {
        video: { projection_type }
      } = source;

      const ingest = { ...source.ingest };
      delete ingest.uuid;
      delete ingest.name;
      payload.source.ingest = ingest;

      if (!FisheyeProjectionTypes.includes(projection_type)) {
        delete payload.source.video.fish_eye_params;
      }

      if (payload.output?.egress) {
        payload.egress = payload.output?.egress;
      } else {
        const egress = { ...payload.output };
        delete egress.uuid;
        delete egress.name;
        delete egress.base_url;
        delete egress.force_single_part_byte_range;
        payload.egress = [egress];
      }
      delete payload.output;

      const bundles = encoding_v3[0].video.bundles;
      payload.encoding_v3[0].video.bundles = mapToAllBundles(bundles);
    }

    removeNoValueFromPayload(payload);
    dispatch(scheduleVodEncode(payload));
    handleCancel();
  };

  const populateValues = encode => {
    const { name, comment, source, encoding_v3, output, drm, advanced } = {
      ...encode
    };
    const { projection_type, fish_eye_params, src_file } = { ...source.video };
    const {
      video,
      auto_add_stereo_downmix,
      auto_audio_kbps_per_channel,
      output_folder,
      packaging_format
    } = {
      ...encoding_v3?.[0]
    };
    const { bundles, gaussian_sharpness_filter, ...rest } = { ...video };
    return {
      name,
      comment: comment || '',
      advanced: {
        accept_input_failures: advanced?.accept_input_failures || false
      },
      source: {
        ingest: source.ingest,
        video: {
          projection_type,
          fish_eye_params: {
            camera_lens_enum: fish_eye_params?.camera_lens_enum || ''
          },
          src_file: src_file?.trim() || ''
        }
      },
      encoding_v3: [
        {
          packaging_format: packaging_format || PACKAGING_FORMAT_DEFAULT,
          output_folder: output_folder || '',
          video: {
            bundles: bundles?.map(
              ({
                rate_control_preset,
                rate_control_custom_bitrate_in_kbps,
                optimized_for_decoder_level,
                down_conversion_factor,
                scale_factor,
                target_fov_in_degrees,
                ...bundle
              }) => ({
                ...parseRatePreset({
                  rate_control_preset,
                  rate_control_custom_bitrate_in_kbps
                }),
                optimized_for_decoder_level,
                down_conversion_factor: down_conversion_factor || 0,
                target_fov_in_degrees: {
                  v_fov_in_deg: target_fov_in_degrees?.v_fov_in_deg || 0,
                  h_fov_in_deg: target_fov_in_degrees?.h_fov_in_deg || 0
                },
                scale_factor: scale_factor ? Math.sqrt(scale_factor) * 100 : 0,
                ...bundle
              })
            ),
            gaussian_sharpness_filter: gaussian_sharpness_filter || 0,
            ...rest
          },
          auto_add_stereo_downmix: auto_add_stereo_downmix || false,
          auto_audio_kbps_per_channel: auto_audio_kbps_per_channel || 64
        }
      ],
      output,
      drm: {
        type: drm?.type || 'none',
        name: '',
        override_content_id: ''
      },
      notification: parseNotification(encode?.notification)
    };
  };

  useEffect(() => {
    if (encode_uuid) dispatch(getVodEncode(encode_uuid));
  }, [profile?.uuid, encode_uuid, dispatch]);

  useEffect(() => {
    if (!profile?.uuid) handleCancel();
  }, [profile?.uuid]); // eslint-disable-line

  useEffect(() => {
    if (!encode) return;

    let data;
    if (encode?.template_hls) {
      data = populateTemplateValues({ encode_config: encode });
      data.template_publication_folder = `${timestamp}_${data.name}`;
    } else {
      const setDestinationValues = encode => {
        const { output, egress } = { ...encode };
        const destination = egress ? egress[0] : output;
        const isNoOutput = destination?.type === 'null';
        return isNoOutput
          ? { uuid: 'null', name: 'No output' }
          : { uuid: nanoid(), name: 'Original destination(s)' };
      };

      const initial = {
        source: {
          uuid: nanoid(),
          name: 'Original source',
          ...encode?.source?.ingest
        },
        destination: setDestinationValues(encode)
        // destination: { uuid: nanoid(), name, ...encode?.output }
      };
      const storage = encode?.output || { egress: encode?.egress };
      initial['destination'] = { ...initial['destination'], ...storage };
      setInitialLocation(initial);

      data = populateValues({
        ...encode,
        source: { ...encode.source, ingest: initial.source },
        output: initial.destination
      });

      if (data.output?.uuid !== 'null') {
        data.encoding_v3[0].output_folder = `${timestamp}_${data.name}`;
      }
    }
    setData(data);
  }, [encode, profile]); // eslint-disable-line

  return (
    <main className='main-container live-container-scroll pt-4 pb-5'>
      {userLoading || loading ? (
        <Loader fullscreen />
      ) : (
        <section className='row'>
          {showModal && (
            <CancelModal
              show={showModal}
              onHide={() => setShowModal(false)}
              onConfirm={handleCancel}
            />
          )}
          <div className={`col-12 ${isTemplate ? '' : 'col-xl-8'}`}>
            <span className='d-flex justify-content-between align-items-center mb-4'>
              <span className='d-flex justify-content-between align-items-center gap-2'>
                <h1 className='h3 color-high m-0'>
                  {editSection ? (
                    <div className='d-flex gap-3'>
                      <span
                        className='hover-primary transition pe-pointer d-flex align-items-center'
                        onClick={() => setEditSection(null)}
                      >
                        <BackIcon />
                      </span>
                      {`Edit ${editSection}`}
                    </div>
                  ) : (
                    t('restart_encode')
                  )}
                </h1>
              </span>
              <EmptyButton onClick={() => setShowModal(true)}>{t('cancel')}</EmptyButton>
            </span>
            <div>
              {encode && Object.keys(encode).length === 0 ? (
                <p className='color-high'>No available encode data</p>
              ) : editSection ? (
                <Formik
                  initialValues={data}
                  validationSchema={Manual[schemaKey][editSection]}
                  onSubmit={handleSave}
                  enableReinitialize
                >
                  {({ dirty, ...props }) => (
                    <Form className='fade-in'>
                      {VOD_URL_TYPES.map(type => (
                        <Fragment key={type}>
                          {editSection === type && (
                            <FormSection
                              type={type}
                              className={editSection === type ? '' : 'invisible'}
                              timestamp={timestamp}
                              initialOption={initialLocation}
                              {...props}
                            />
                          )}
                        </Fragment>
                      ))}
                      <Button
                        type='submit'
                        variant='primary'
                        className='shadow-none mt-4 gap-3'
                      >
                        {t('save')}
                      </Button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <>
                  <OverviewSection data={data} onClick={type => setEditSection(type)} />
                  <Button
                    variant='primary'
                    className='shadow-none mt-4 gap-3'
                    onClick={handleRestart}
                  >
                    {t('restart')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default RestartEncodePage;
