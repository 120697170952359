import i18n from 'config/i18next';
import { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from 'store/live/liveSlice';
import { setVodProfile } from 'store/vod/vodSlice';

import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'components/Badge';
import WarningIcon from 'remixicon-react/ErrorWarningFillIcon';

const sortName = data =>
  data?.slice()?.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { profile_config } = useSelector(({ live }) => live);
  const { profile } = useSelector(({ vod }) => vod);
  const {
    default_live_uuid,
    default_vod_uuid,
    live_profiles,
    vod_profiles,
    settings: { theme },
    loading
  } = useSelector(({ user }) => user);

  const { push } = useHistory();
  const { pathname } = useLocation();
  const [, , platform] = pathname.split('/');

  const show = ['live', 'vod'].includes(platform);
  const isLive = platform === 'live';
  const currentProfile = isLive ? profile_config : profile;
  const profiles = isLive ? live_profiles : vod_profiles.encoders;
  const sortedProfiles = sortName(profiles);
  const noProfileText = isLive ? 'No live profile' : 'No encoder profile';

  const handleClick = profile => {
    if (isLive) return dispatch(setProfile(profile));
    dispatch(setVodProfile(profile));
    push(`/${i18n.language}/vod`);
  };

  if (!show || loading) return null;
  if (!profiles?.length) {
    const renderTooltip = props => (
      <Tooltip id='button-tooltip' {...props}>
        Request a profile config from Tiledmedia
      </Tooltip>
    );
    return (
      <div className='color-high d-flex align-content-center gap-1'>
        {noProfileText}
        <OverlayTrigger placement='bottom' overlay={renderTooltip}>
          <span>
            <WarningIcon className='text-danger' size={24} />
          </span>
        </OverlayTrigger>
      </div>
    );
  }
  return (
    <Dropdown
      className={`profile-dropdown ${profiles?.length > 1 ? '' : 'no-menu pe-none'}`}
    >
      <Dropdown.Toggle className='color-high'>
        {currentProfile?.name || noProfileText}
      </Dropdown.Toggle>
      {profiles?.length > 1 && (
        <Dropdown.Menu
          variant={theme}
          className='overflow-auto shadow-sm'
          style={{ maxHeight: '50vh', minWidth: '15rem' }}
        >
          {sortedProfiles?.map(profile => {
            const isActive = currentProfile?.uuid === profile.uuid;
            const isDefault = [default_live_uuid, default_vod_uuid].includes(
              profile.uuid
            );
            return (
              <Fragment key={profile.uuid}>
                <Dropdown.Item
                  className='py-2'
                  onClick={() => handleClick(profile)}
                  disabled={isActive}
                >
                  {profile.name}
                  {isDefault && (
                    <h6 className={`d-inline-block m-0 ${isActive ? 'opacity-38' : ''}`}>
                      <Badge.Line className='align-middle ms-2' isDefault />
                    </h6>
                  )}
                </Dropdown.Item>
              </Fragment>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default ProfileDropdown;
