import { getTrafficLightBg } from 'utils/liveUtils';

const StatusIndicator = ({ show, status, className = '' }) => {
  if (!show) return null;
  const color = getTrafficLightBg(status);
  return (
    <span
      className={`${className} position-absolute top-0 end-0 rounded-circle bg-${color} mx-1 p-1 pop-up`}
    />
  );
};

export default StatusIndicator;
