import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { Formik, Form } from 'formik';
import { createVodProfile, updateVodProfile } from 'store/user/userThunk';
import { actionTypes } from 'constants/live';
import { VodSettingsFormConfigs } from 'constants/vodForm';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Subtitle from 'components/VodSubtitle';
import { FormFields } from 'components/VodFields';
import WithLoader from 'components/WithLoader';

const VodEncoderForm = ({ button }) => {
  const { uuid, action, type } = useParams();
  const { goBack } = useHistory();
  const isCreate = action === 'new';

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { vod_profiles } = useSelector(({ user }) => user);

  const dataFormat = { ...VodSettingsFormConfigs[type] };
  const { initialValues, fields, schema } = dataFormat;
  const profiles = vod_profiles['encoders'];
  const currentProfile = profiles?.find(({ uuid: id }) => id === uuid);
  const initial = isCreate
    ? initialValues
    : currentProfile?.notification?.type === 'none'
    ? { ...currentProfile, notification: initialValues.notification }
    : currentProfile;

  const handleSubmit = async values => {
    const isEdit = action === actionTypes.edit;
    let payload = { data: { ...values }, type: 'encoders' };

    if (!isEdit) payload['data']['uuid'] = nanoid();
    if (values?.notification?.type === 'none') {
      payload.data.notification = { type: 'none' };
    }

    const response = isEdit
      ? await dispatch(updateVodProfile(payload))
      : await dispatch(createVodProfile(payload));

    if (response['meta']['requestStatus'] === 'fulfilled') {
      goBack();
    }
  };

  return (
    <Formik
      initialValues={initial}
      validationSchema={schema}
      onSubmit={values => handleSubmit(values)}
      validateOnMount={!isCreate}
      enableReinitialize
    >
      <Form>
        <Card className='modal-background dp-01 rounded'>
          <Card.Body className='p-4'>
            <Subtitle type={type} text='settings' />
            <FormFields fields={fields} />
          </Card.Body>
        </Card>
        <Button type='submit' variant='primary' className='border-0 shadow-none mt-4'>
          {t(button)}
        </Button>
      </Form>
    </Formik>
  );
};

export default WithLoader(VodEncoderForm);
