// import i18n from 'config/i18next';
import jwtDecode from 'jwt-decode';
import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import {
  authenticateUser,
  setNewPassword,
  forgotPassword,
  resetPassword,
  getSession,
  getCognitoGroups
} from 'services/authService';
import { getUserData, getUserBlueprints, updateUserData } from 'services/userService';
import { formatProfileWithNewUUID } from 'utils/utils';
import { VOD_TYPES } from 'constants/vod';

/**
 * Cognito API: response.payload
 */

export const loginUser = credentials => async dispatch => {
  let jwtData;
  let userData;

  const response = await dispatch(authUser(credentials));

  if (response.payload?.isFirstLogin) {
    return response.payload;
  }

  if (response.meta.requestStatus === 'fulfilled') {
    jwtData = jwtDecode(response.payload);
    userData = await dispatch(getUser());
  }

  if (!userData) return;
  if (userData.meta.requestStatus === 'fulfilled') {
    // not first login if the email is initialized
    if (userData.payload?.email) return userData;

    // setup initial user data for the first login
    userData = userData.payload;
    const initialUserData = {
      ...userData,
      username: userData?.username || jwtData['cognito:username'],
      email: userData?.email || jwtData.email,
      settings: {
        theme: userData?.settings?.theme || 'light',
        // language: i18n.language,
        hide_getting_started: userData?.settings?.hide_getting_started || false
      }
    };
    const formattedUserData = JSON.stringify(initialUserData);
    return await dispatch(updateUser(formattedUserData));
  }
};

export const authUser = createAsyncThunk(
  'user/authUser',
  async credentials => await authenticateUser(credentials)
);

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
  const response = await getSession();
  return response ? true : false;
});

export const setNewUserPassword = createAsyncThunk(
  'user/setNewUserPassword',
  async credentials => await setNewPassword(credentials)
);

export const forgotUserPassword = createAsyncThunk(
  'user/forgotUserPassword',
  async username => await forgotPassword(username)
);

export const resetUserPassword = createAsyncThunk(
  'user/resetUserPassword',
  async newUserData => await resetPassword(newUserData)
);

/**
 * Cockpit backend API: response.data
 */

export const getBlueprints = createAsyncThunk('user/getBlueprints', async () => {
  try {
    const response = await getUserBlueprints();
    return response.data;
  } catch (error) {
    throw Error(error);
  }
});

export const getUser = createAsyncThunk('user/getUser', async () => {
  try {
    const response = await getUserData();
    const groups = await getCognitoGroups();
    const data = { ...response.data, groups };
    return data;
  } catch (error) {
    throw Error(error);
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async newUserData => {
  try {
    const response = await updateUserData(newUserData);
    const groups = await getCognitoGroups();
    const data = { ...response.data, groups };
    return data;
  } catch (error) {
    throw Error(error);
  }
});

/**
 * LIVE PROFILES
 */
export const createLiveProfile = createAsyncThunk(
  'user/createLiveProfile',
  async (data, { getState }) => {
    const { live_profiles = [], ...userData } = getState()['user'];
    const profiles = Array.isArray(data) ? [...data] : [data];
    const formattedProfiles = profiles.map(profile => formatProfileWithNewUUID(profile));
    const newData = {
      ...userData,
      live_profiles: [...live_profiles, ...formattedProfiles]
    };
    try {
      const response = await updateUserData(newData);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const importLiveProfile = createAsyncThunk(
  'user/importLiveProfile',
  async (data, { getState }) => {
    const { live_profiles, ...userData } = getState()['user'];
    const formattedProfiles = data?.map(profile => formatProfileWithNewUUID(profile));
    const newData = {
      ...userData,
      live_profiles: live_profiles?.length
        ? [...live_profiles, ...formattedProfiles]
        : formattedProfiles
    };
    try {
      const response = await updateUserData(newData);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const updateLiveProfile = createAsyncThunk(
  'user/updateLiveProfile',
  async (newProfile, { getState }) => {
    const userData = getState().user;
    const profiles = userData?.live_profiles || [];

    const index = profiles.findIndex(({ uuid }) => uuid === newProfile.uuid);
    const updatedProfiles = [...profiles];
    updatedProfiles[index] = {
      ...newProfile,
      set_id: newProfile?.set_id || '',
      customer_entrypoint: newProfile?.customer_entrypoint || ''
    };

    const newUserData = {
      ...userData,
      live_profiles: updatedProfiles
    };

    try {
      const response = await updateUserData(newUserData);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const deleteLiveProfile = createAsyncThunk(
  'user/deleteLiveProfile',
  async (deleteUUIDs, { getState }) => {
    const { live_profiles, ...userData } = getState()['user'];
    let payload = {
      ...userData,
      live_profiles: live_profiles.filter(({ uuid }) => !deleteUUIDs.includes(uuid))
    };
    const isDeleteDefault = deleteUUIDs.includes(userData.default_live_uuid);
    if (isDeleteDefault) payload.default_live_uuid = null;
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

/**
 * VOD PROFILES
 */
export const createVodProfile = createAsyncThunk(
  'user/createVodProfile',
  async ({ type, data }, { getState }) => {
    const { vod_profiles, ...userData } = getState()['user'];
    const profiles = vod_profiles[type] || [];
    const newProfiles = Array.isArray(data) ? [...data] : [data];
    const payload = {
      ...userData,
      vod_profiles: {
        ...vod_profiles,
        [type]: [...profiles, ...newProfiles]
      }
    };
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const importVodProfile = createAsyncThunk(
  'user/importVodProfile',
  async (importedData, { getState }) => {
    const {
      vod_profiles: { encoders = [], sources = [], destinations = [] },
      ...userData
    } = getState()['user'];
    const profiles = { ...importedData };
    VOD_TYPES.map(key => {
      return (profiles[key] = profiles[key]?.length
        ? profiles[key].map(profile => {
            const data = { ...profile };
            data['uuid'] = nanoid();
            return data;
          })
        : []);
    });
    const payload = {
      ...userData,
      vod_profiles: {
        encoders: [...encoders, ...profiles['encoders']],
        sources: [...sources, ...profiles['sources']],
        destinations: [...destinations, ...profiles['destinations']]
      }
    };
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const updateVodProfile = createAsyncThunk(
  'user/updateVodProfile',
  async ({ type, data }, { getState }) => {
    const { vod_profiles, ...userData } = getState()['user'];
    const { [type]: profiles, ...rest } = vod_profiles;
    let updatedProfiles;
    if (profiles?.length) {
      updatedProfiles = [...profiles];
      const index = profiles.findIndex(({ uuid }) => uuid === data.uuid);
      const {
        uuid,
        name,
        type: locType,
        force_single_part_byte_range,
        file_size_limit,
        folder
      } = updatedProfiles[index];
      updatedProfiles[index] = {
        uuid,
        name,
        type: locType,
        force_single_part_byte_range,
        file_size_limit,
        folder,
        ...data
      };
    } else updatedProfiles = [data];
    const payload = {
      ...userData,
      vod_profiles: { ...rest, [type]: updatedProfiles }
    };
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const deleteVodProfile = createAsyncThunk(
  'user/deleteVodProfile',
  async (deleteUUIDs, { getState }) => {
    const { vod_profiles, ...userData } = getState()['user'];
    const remainingProfiles = {};
    Object.keys(vod_profiles).map(
      key =>
        (remainingProfiles[key] = vod_profiles[key]?.filter(
          ({ uuid }) => !deleteUUIDs.includes(uuid)
        ))
    );
    const payload = { ...userData, vod_profiles: remainingProfiles };
    const isDeleteDefault = deleteUUIDs.includes(userData.default_vod_uuid);
    if (isDeleteDefault) payload.default_vod_uuid = null;
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

/**
 * SETTINGS
 */
export const updateSettings = createAsyncThunk(
  'user/updateSettings',
  async (updatedSettings, { getState }) => {
    const currentUserData = getState()['user'];
    const currentSettings = currentUserData?.settings;
    const newUserData = {
      ...currentUserData,
      settings: {
        ...currentSettings,
        ...updatedSettings
      }
    };
    try {
      const response = await updateUserData(newUserData);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);
