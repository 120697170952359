import Image from 'react-bootstrap/Image';

const Step = ({ step, currentStep }) => {
  const centerAlign = 'd-flex justify-content-center align-items-center';
  const active = parseInt(currentStep) === parseInt(step?.index);
  const checked = parseInt(currentStep) > parseInt(step?.index);

  if (!step) return;

  return (
    <div
      className={`${
        active ? 'color-high' : 'color-disabled'
      } ${centerAlign} transition w-auto p-0 py-4 me-5`}
    >
      <div className='me-3'>
        <Image
          className={`position-absolute ${checked ? 'visible' : 'invisible'}`}
          src={`${process.env.REACT_APP_COCKPIT_CDN}/icons/step-icon-checked.svg`}
        />
        <span
          className={`border-2 rounded-circle ${centerAlign} 
            ${checked ? 'invisible' : 'visible'} 
            ${active ? 'border-high' : 'border-disabled'}
          `}
          style={{ width: '2rem', height: '2rem' }}
        >
          <p className='h6 w-auto m-0'>{step?.index}</p>
        </span>
      </div>
      <h2 className='h5 w-auto m-0'>{step?.text}</h2>
    </div>
  );
};

export default Step;
