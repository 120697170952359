import { Fragment, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { actionTypes } from 'constants/live';
import { RATE_CONTROL } from 'constants/vod';
import { VodStartFormConfigs as VodFields } from 'constants/vodForm';
import { getPreset, showCustomBitrate, checkIsTM } from 'utils/utils';
import {
  getLocationTypeOption,
  renderNotificationTargetLable
} from 'utils/vodUtils';

import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import TextField from 'components/TextField';

// VOD
export const LocationSelect = ({ type, initialOption }) => {
  const { t } = useTranslation('validation');
  const name = type === 'source' ? 'source.ingest.uuid' : 'output.uuid';
  const [field, meta, helpers] = useField(name);
  const { groups, vod_profiles } = useSelector(({ user }) => user);
  const { sources = [], destinations = [] } = { ...vod_profiles };

  const httpType = type === 'source' ? 'http-pull' : 'http-put';
  const httpOption = { uuid: httpType, name: '', type: httpType };
  let options =
    type === 'source'
      ? [...sources, httpOption]
      : [...destinations, httpOption];
  if (
    checkIsTM(groups) &&
    type === 'destination' &&
    initialOption?.uuid !== 'null'
  ) {
    options.unshift({ uuid: 'null', name: 'No output' });
  }
  if (initialOption) options = [initialOption, ...options];

  const setValidStyles = ({ touched, error }) => {
    if (options && !touched) return 'border-line';
    return error ? 'is-invalid' : 'is-valid';
  };

  useEffect(() => {
    if (!options.length) helpers.setTouched(true);
    if (options.length === 1) helpers.setValue(options[0].uuid);
  }, []); // eslint-disable-line

  return (
    <div className='row'>
      <div className='input-height col-12 col-lg-8'>
        <label htmlFor={name} className='form-label color-medium'>
          {t('location')}
        </label>
        <select className={`form-select ${setValidStyles(meta)}`} {...field}>
          {!initialOption && (
            <option value=''>
              {options.length ? 'Choose a location' : 'No available location'}
            </option>
          )}
          {options.map(({ uuid, name, type, egress }) => {
            const types = egress || [{ type }];
            const location_type = types.map(({ type }) =>
              getLocationTypeOption(type)
            );
            let option = name;
            if (location_type && uuid !== 'null') {
              option = `[${location_type}] ${option}`;
            }
            return (
              <option key={uuid} value={uuid}>
                {option}
              </option>
            );
          })}
        </select>
        {!options.length ? (
          <div className='invalid-feedback'>
            Please add a location in Settings
          </div>
        ) : (
          <ErrorMessage name={field.name}>
            {message => <div className='invalid-feedback'>{t(message)}</div>}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
};

const InitialUntouchedField = ({ field, name, ...rest }) => {
  const [, , helpers] = useField(name);

  useEffect(() => {
    helpers.setTouched(false);
  }, []); // eslint-disable-line
  return <TextField {...field} name={name} {...rest} />;
};

const ABRField = ({ name, bundle, index = 0, isMultiple }) => {
  const [initialConstant, setInitialConstant] = useState('');
  const { pathname } = useLocation();
  const isVod = pathname.split('/')[2] === 'vod';
  const isStartVodEncode = isVod && pathname.endsWith('new');
  const {
    decoder,
    rateControl: { constant, preset, custom_bitrate }
  } = VodFields.fields;
  const modal = useSelector(({ modal }) => modal);
  const isHLS = modal?.content?.type === 'hls';
  const isActive = modal.name === actionTypes.update;

  const getConstantOptions = initialConstant => {
    const options = RATE_CONTROL['constant'].filter(option =>
      isHLS ? option : option !== 'quality'
    );
    if (isVod) return options;
    if (initialConstant === 'full-custom') return options;
    return options.filter(option => option !== 'full-custom');
  };

  const getPresetOptions = ({ constant }) => {
    const options = RATE_CONTROL['preset'];
    switch (constant) {
      case 'full-custom':
        return ['custom'];
      case 'bitrate':
        return options.filter(option => option !== 'medium');
      case 'quality':
        const notQualityOptions = ['default', 'custom'];
        return options.filter(option => !notQualityOptions.includes(option));
      default:
        return options;
    }
  };
  bundle.preset = getPreset(bundle);

  const helpMessage =
    bundle.constant === 'full-custom'
      ? 'Minimum 512 or 0 (auto)'
      : 'Minimum 512';

  const rateControlStyles = isMultiple
    ? 'col-12'
    : isStartVodEncode
    ? 'col-12 col-md-4 col-xl-3'
    : 'col-12 col-md-4';

  const rateControlField = (
    <>
      <InitialUntouchedField
        className={rateControlStyles}
        field={{ ...constant, options: getConstantOptions(initialConstant) }}
        name={`${name}.${index}.${constant.name}`}
        disabled={isActive}
      />
      <InitialUntouchedField
        className={rateControlStyles}
        field={{ ...preset, options: getPresetOptions(bundle) }}
        name={`${name}.${index}.${preset.name}`}
        disabled={isActive && isHLS}
      />
      {showCustomBitrate(bundle) && (
        <TextField
          className={`fade-in ${rateControlStyles}`}
          {...custom_bitrate}
          name={`${name}.${index}.${custom_bitrate.name}`}
          helpMessage={helpMessage}
          disabled={isActive && isHLS}
        />
      )}
    </>
  );

  useEffect(() => {
    setInitialConstant(bundle?.constant);
  }, []); // eslint-disable-line
  return (
    <>
      {isVod && (
        <div className='row g-4'>
          <InitialUntouchedField
            className={rateControlStyles}
            field={decoder}
            name={`${name}.${index}.${decoder.name}`}
          />
        </div>
      )}
      <h6 className='color-high mb-3'>Rate control</h6>
      {isMultiple ? (
        <>{rateControlField}</>
      ) : (
        <div className='row g-4'>{rateControlField}</div>
      )}
    </>
  );
};

export const ABRFields = ({ name, bundles, onHide = null }) => {
  const { t } = useTranslation('validation');
  const { theme } = useSelector(({ user }) => user.settings);
  return (
    <>
      {!bundles ? (
        <p className='color-medium'>No data</p>
      ) : bundles?.length > 1 ? (
        <CardGroup className='flex-nowrap overflow-auto gap-3 pb-2'>
          {bundles.map((bundle, index) => (
            <Modal.Dialog
              key={index}
              className='bg-transparent border-line rounded m-0 col-3'
              style={{ width: '40%' }}
            >
              <Modal.Header
                className='align-items-start pb-2'
                onHide={() => onHide(index)}
                closeButton={onHide}
                closeVariant={theme === 'dark' ? 'white' : null}
              >
                <h6 className='color-primary m-0'>{`${t('level')} ${
                  index + 1
                }`}</h6>
              </Modal.Header>
              <Modal.Body>
                {bundle && (
                  <ABRField
                    index={index}
                    name={name}
                    bundle={bundle}
                    isMultiple
                  />
                )}
              </Modal.Body>
            </Modal.Dialog>
          ))}
        </CardGroup>
      ) : (
        <ABRField name={name} bundle={bundles?.[0]} />
      )}
    </>
  );
};

// Settings
export const FormFields = ({ fields, index = null }) => {
  const { values } = useFormikContext();
  const notificationType = values?.notification?.type;

  const parseName = name =>
    Number.isInteger(index) ? `akamai_multi_ns.${index}.${name}` : name;
  return (
    <>
      {fields?.map(({ uuid, name, className, cols, ...rest }) => (
        <Fragment key={uuid}>
          {cols ? (
            <div className={className}>
              {cols.map(({ uuid, name, ...col }) => {
                const isNotificationTarget = name === 'notification.target';
                const showNotificationTarget = isNotificationTarget
                  ? notificationType && notificationType !== 'none'
                  : true;

                if (isNotificationTarget) {
                  col['label'] =
                    renderNotificationTargetLable(notificationType);
                }
                return (
                  <Fragment key={uuid}>
                    {showNotificationTarget && (
                      <TextField name={parseName(name)} {...col} />
                    )}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <TextField name={parseName(name)} className={className} {...rest} />
          )}
        </Fragment>
      ))}
    </>
  );
};

export const LocationFields = ({ data, error, options, onChange }) => {
  const [isFocus, setIsFocus] = useState(false);
  const focusRef = useRef(null);

  const setValidStyles = error => {
    if (!error) return;
    if (error) return 'is-invalid';
    else return 'is-valid';
  };

  useEffect(() => {
    if (isFocus) focusRef.current.focus();
  }, [isFocus]);

  useEffect(() => {
    if (options.includes(data?.type)) setIsFocus(true);
    const cancelFocus = setTimeout(() => setIsFocus(false), 1000);
    return () => clearTimeout(cancelFocus);
  }, [data?.type, options]);

  return (
    <section className='row g-3'>
      <div className='col-md-5 input-height'>
        <label htmlFor='type' className='form-label color-medium'>
          Type
        </label>
        <select
          name='type'
          className={`form-select ${setValidStyles(error?.type)}`}
          aria-label='type'
          value={data?.type}
          onChange={onChange}
        >
          <option>Select a location type</option>
          {options?.map(item => (
            <option key={item} value={item}>
              {getLocationTypeOption(item)}
            </option>
          ))}
        </select>
        {error?.type && <div className='invalid-feedback'>{error.type}</div>}
      </div>
      <div className='col-md-7 input-height'>
        <label htmlFor='name' className='form-label color-medium'>
          Name
        </label>
        <input
          ref={focusRef}
          id='name'
          name='name'
          className={`form-control ${setValidStyles(error?.name)}`}
          value={data?.name}
          onChange={onChange}
        />
        {error?.name && <div className='invalid-feedback'>{error.name}</div>}
      </div>
      {data?.base_url !== undefined && (
        <div className='col-12 input-height'>
          <label htmlFor='base-url' className='form-label color-medium'>
            Base access URL
          </label>
          <input
            id='base-url'
            name='base_url'
            className='form-control'
            value={data?.base_url}
            onChange={onChange}
          />
        </div>
      )}
    </section>
  );
};
