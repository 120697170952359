import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, getUser } from 'store/user/userThunk';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const isAuthenticated = useSelector(({ user }) => user.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isAuthenticated) dispatch(getUser());
  }, [isAuthenticated, dispatch]);

  return (
    <Route
      render={props => {
        if (!isAuthenticated) {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        }
        return Component ? <Component {...props} /> : render(props);
      }}
      {...rest}
    />
  );
};

export default ProtectedRoute;
