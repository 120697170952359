import 'components/ConfigMethodOption/configMethodOption.styles.scss';

const FileWrapper = ({ disabled, children, onChange, onClick, className }) => (
  <div
    className={`file-container transition w-100 p-0 position-relative ${className}`}
    onClick={onClick}
  >
    <input
      type='file'
      disabled={disabled}
      accept='application/JSON'
      className='input w-100 h-100 pe-pointer opacity-0'
      onChange={event => onChange(event)}
      aria-label='choose a file'
    />
    <div className='w-100 h-100 position-absolute top-0 pe-none'>
      {children}
    </div>
  </div>
);

const OptionButton = ({
  icon,
  content,
  selected,
  onClick,
  file,
  className
}) => (
  <button
    className={`${className} option-button transition modal-background btn border-2 border-${
      selected && file?.data ? 'focus' : 'transparent'
    } shadow-sm d-flex align-items-center w-100 px-4 rounded`}
    onClick={onClick}
  >
    <span className='mx-2 color-high'>{icon}</span>
    <div className='ms-4 text-start'>
      <h3 className='h5 color-high'>{content?.title}</h3>
      <p
        className={`${
          file ? 'color-primary' : 'color-disabled'
        } base-font fw-normal m-0`}
      >
        {file?.name || content?.description}
      </p>
    </div>
  </button>
);

const ConfigMethodOption = ({ file, onChange, ...props }) => (
  <>
    {props?.openFile ? (
      <FileWrapper onClick={props?.onClick} onChange={onChange}>
        <OptionButton file={file} {...props} />
      </FileWrapper>
    ) : (
      <OptionButton {...props} />
    )}
  </>
);

export default ConfigMethodOption;
