import { createSlice } from '@reduxjs/toolkit';
import {
  getLiveConfigs,
  getRunningConfigs,
  getLiveStatus,
  getRunningStatus,
  startLiveConfigs,
  stopLiveConfigs,
  updateLiveConfig,
  quickRestartLiveConfig,
  clearRecentErrors,
  fetchSnapshot,
  fetchSnapshotHD
} from 'store/live/liveThunk';

export const initialState = {
  profile_config: null,
  metrics_version: 0,
  live_configs: null,
  live_details: { loading: false },
  snapshot: {
    loading: true,
    index: { current: 0, all: [] },
    img: null,
    img_hd: null,
    time: null
  },
  charts: {
    configs: [],
    panels: []
  },
  loading: false,
  error: null
};

const liveSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {
    setLiveLoading: (state, { payload }) => {
      state.loading = payload;
    },
    clearLiveConfigs: state => {
      state.live_configs = null;
    },
    clearLiveDetails: state => {
      state.live_details = { loading: false };
    },
    setProfile: (state, { payload }) => {
      state.profile_config = payload;
    },
    clearProfile: state => {
      state.profile_config = null;
    },
    setChartConfigs: (state, { payload }) => {
      state.charts.configs = payload;
    },
    removeChartConfig: (state, { payload }) => {
      state.charts.configs.splice(payload, 1);
    },
    addPanel: (state, { payload }) => {
      state.charts.panels.push(payload);
    },
    removePanel: (state, { payload }) => {
      state.charts.panels.splice(payload, 1);
    },
    setAllPanels: (state, { payload }) => {
      state.charts.panels = payload;
    },
    setSnapshotsCount: (state, { payload }) => {
      state.snapshot.index = payload;
    },
    setSnapshotCurrentIndex: (state, { payload }) => {
      state.snapshot.index.current = payload;
    },
    switchSnapshot: state => {
      state.snapshot.img = null;
      state.snapshot.time = null;
      state.snapshot.img_hd = null;
    },
    clearSnapshot: state => {
      state.snapshot.index = { current: 0, all: [] };
      state.snapshot.img = null;
      state.snapshot.time = null;
      state.snapshot.img_hd = null;
    }
  },
  extraReducers: {
    [getLiveConfigs.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [getLiveConfigs.fulfilled]: (state, { payload }) => {
      state.live_configs = payload.live_configs;
      state.metrics_version = payload?.metrics_version || 0;
      state.loading = false;
    },
    [getLiveConfigs.rejected]: (state, { error }) => {
      state.live_configs = null;
      state.loading = false;
      state.error = error.message;
    },
    [getRunningConfigs.pending]: state => {
      state.error = null;
    },
    [getRunningConfigs.fulfilled]: (state, { payload }) => {
      state.live_configs = payload.live_configs;
      state.metrics_version = payload?.metrics_version || 0;
    },
    [getRunningConfigs.rejected]: (state, { error }) => {
      state.error = error.message;
    },
    [getLiveStatus.pending]: state => {
      state.live_details.loading = true;
      state.error = null;
    },
    [getLiveStatus.fulfilled]: (state, { payload }) => {
      state.live_details.loading = false;
      state.live_details = payload;
      state.error = null;
    },
    [getLiveStatus.rejected]: (state, { error }) => {
      state.live_details.loading = false;
      state.error = error.message;
    },
    [getRunningStatus.pending]: state => {
      state.error = null;
    },
    [getRunningStatus.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.live_details = payload;
    },
    [getRunningStatus.rejected]: (state, { error }) => {
      state.error = error.message;
    },
    [startLiveConfigs.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [startLiveConfigs.fulfilled]: (state, { payload }) => {
      state.profile_config.config_uuid = payload.config_uuid;
      state.loading = false;
    },
    [startLiveConfigs.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [stopLiveConfigs.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [stopLiveConfigs.fulfilled]: state => {
      state.loading = false;
    },
    [stopLiveConfigs.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updateLiveConfig.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [updateLiveConfig.fulfilled]: state => {
      state.loading = false;
    },
    [updateLiveConfig.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [quickRestartLiveConfig.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [quickRestartLiveConfig.fulfilled]: state => {
      state.loading = false;
    },
    [quickRestartLiveConfig.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [clearRecentErrors.pending]: state => {
      // state.loading = true;
      state.error = null;
    },
    [clearRecentErrors.fulfilled]: state => {
      // state.loading = false;
      // state.error = null;
    },
    [clearRecentErrors.rejected]: (state, { error }) => {
      // state.loading = false;
      state.error = error.message;
    },
    [fetchSnapshot.pending]: state => {
      state.snapshot.loading = true;
      state.error = null;
    },
    [fetchSnapshot.fulfilled]: (state, { payload }) => {
      state.snapshot.loading = false;
      state.snapshot.img = payload.img;
      state.snapshot.time = payload.time;
      state.error = null;
    },
    [fetchSnapshot.rejected]: (state, { error }) => {
      state.snapshot.loading = false;
      state.error = error.message;
    },
    [fetchSnapshotHD.pending]: state => {
      state.snapshot.loading = true;
      state.error = null;
    },
    [fetchSnapshotHD.fulfilled]: (state, { payload }) => {
      state.snapshot.loading = false;
      state.snapshot.img_hd = payload;
      state.error = null;
    },
    [fetchSnapshotHD.rejected]: (state, { error }) => {
      state.snapshot.loading = false;
      state.error = error.message;
    }
  }
});

export const {
  setLiveLoading,
  clearLiveConfigs,
  clearLiveDetails,
  setProfile,
  clearProfile,
  setChartConfigs,
  removeChartConfig,
  addPanel,
  removePanel,
  setAllPanels,
  setSnapshotsCount,
  setSnapshotCurrentIndex,
  switchSnapshot,
  clearSnapshot
} = liveSlice.actions;

export default liveSlice.reducer;
