import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import CopyIcon from 'remixicon-react/FileCopyLineIcon';

export const CopyButton = ({ value, size, className = '' }) => {
  const target = useRef(null);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const theme = useSelector(({ user }) => user?.settings?.theme);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <>
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <Button
          ref={target}
          variant={theme}
          className={`z-1 color-medium btn-action btn-transition border-line input-background ${className}`}
          disabled={!value?.toString()?.trim() || copied}
        >
          <CopyIcon size={size} />
        </Button>
      </CopyToClipboard>
      <Overlay target={target.current} show={copied} placement='top'>
        <Tooltip>{t('copied')}</Tooltip>
      </Overlay>
    </>
  );
};

export default CopyButton;
