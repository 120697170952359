import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getConfigs,
  getStatus,
  startConfig,
  stopConfig,
  updateConfig,
  restartConfig,
  clearErrors,
  getSnapshot
} from 'services/liveService';

export const getLiveConfigs = createAsyncThunk(
  'live/getLiveConfigs',
  async ({ customer_id, api_auth_token, set_id, customer_entrypoint }) => {
    try {
      const response = await getConfigs(customer_entrypoint, {
        customer_id,
        api_auth_token,
        set_id
      });
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const getRunningConfigs = createAsyncThunk(
  'live/getRunningConfigs',
  async ({
    customer_id,
    api_auth_token,
    set_id,
    customer_entrypoint,
    source
  }) => {
    try {
      const response = await getConfigs(
        customer_entrypoint,
        { customer_id, api_auth_token, set_id },
        source
      );
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const getLiveStatus = createAsyncThunk(
  'live/getLiveStatus',
  async ({
    customer_id,
    api_auth_token,
    customer_entrypoint,
    stack_uuid,
    source
  }) => {
    try {
      const response = await getStatus(
        customer_entrypoint,
        {
          customer_id,
          api_auth_token,
          stack_uuid
        },
        source
      );
      const { nodes_in_stack, ...rest } = { ...response.data };
      return { stack_uuid, ...rest }; // TODO: stack_uuid?
    } catch (error) {
      throw Error(error);
    }
  }
);

export const getRunningStatus = createAsyncThunk(
  'live/getRunningStatus',
  async ({
    customer_id,
    api_auth_token,
    customer_entrypoint,
    stack_uuid,
    source
  }) => {
    try {
      const response = await getStatus(
        customer_entrypoint,
        {
          customer_id,
          api_auth_token,
          stack_uuid
        },
        source
      );
      const { nodes_in_stack, ...rest } = { ...response.data };
      return { stack_uuid, ...rest }; // TODO: stack_uuid?
    } catch (error) {
      throw Error(error);
    }
  }
);

export const startLiveConfigs = createAsyncThunk(
  'live/startLiveConfigs',
  async (liveConfigs, { getState }) => {
    const { customer_entrypoint, name, uuid, ...authConfig } =
      getState().live.profile_config;
    try {
      const responses = [];
      for (let i = 0; i < liveConfigs.length; i++) {
        const response = await startConfig(customer_entrypoint, {
          ...authConfig,
          ...liveConfigs[i]
        });
        responses.push(response.data);
      }
      return responses;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const stopLiveConfigs = createAsyncThunk(
  'live/stopLiveConfigs',
  async (stackUUIDs, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const responses = [];
      for (let i = 0; i < stackUUIDs.length; i++) {
        const response = await stopConfig(customer_entrypoint, {
          customer_id,
          api_auth_token,
          stack_uuid: stackUUIDs[i]
        });
        responses.push(response.data);
      }
      return responses;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const updateLiveConfig = createAsyncThunk(
  'live/updateLiveConfig',
  async ({ stack_uuid, dynamic_config, runtime_parameters }, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const payload = {
        customer_id,
        api_auth_token,
        stack_uuid,
        dynamic_config,
        runtime_parameters
      };

      const response = dynamic_config
        ? await restartConfig(customer_entrypoint, payload)
        : await updateConfig(customer_entrypoint, payload);

      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const quickRestartLiveConfig = createAsyncThunk(
  'live/quickRestartLiveConfig',
  async (stack_uuid, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const payload = { customer_id, api_auth_token, stack_uuid };
      const response = await restartConfig(customer_entrypoint, payload);
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const clearRecentErrors = createAsyncThunk(
  'live/clearRecentErrors',
  async (stack_uuid, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const response = await clearErrors(customer_entrypoint, {
        customer_id,
        api_auth_token,
        stack_uuid
      });
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const fetchSnapshot = createAsyncThunk(
  'live/fetchSnapshot',
  async ({ index: input_idx, ...rest }, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const response = await getSnapshot(customer_entrypoint, {
        customer_id,
        api_auth_token,
        input_idx,
        ...rest
      });
      const { input_projection } = { ...rest };
      if (input_projection) {
        localStorage.setItem(
          'input_projection',
          JSON.stringify(input_projection)
        );
      }
      return {
        img: response?.data,
        time: response?.headers?.['last-modified']
      };
    } catch (error) {
      throw Error(error);
    }
  }
);

export const fetchSnapshotHD = createAsyncThunk(
  'live/fetchSnapshotHD',
  async ({
    customer_id,
    api_auth_token,
    customer_entrypoint,
    stack_uuid,
    index
  }) => {
    try {
      const payload = {
        customer_id,
        api_auth_token,
        stack_uuid,
        input_idx: Number(index)
      };

      const input_projection = localStorage.getItem('input_projection');

      if (input_projection) {
        payload['emphasize_projection_border'] = true;
        payload['input_projection'] = JSON.parse(input_projection);
      }
      const response = await getSnapshot(customer_entrypoint, payload);
      return {
        img: response?.data,
        time: response?.headers?.['last-modified']
      };
    } catch (error) {
      throw Error(error);
    }
  }
);
