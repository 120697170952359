import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createVodProfile, updateVodProfile } from 'store/user/userThunk';
import { getLocationKey } from 'utils/vodUtils';
import { actionTypes } from 'constants/live';
import { LocationTypes } from 'constants/vod';
import { VodSettingsFormConfigs } from 'constants/vodForm';

import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Subtitle from 'components/VodSubtitle';
import { LocationFields, FormFields } from 'components/VodFields';
import WithLoader from 'components/WithLoader';

const VodStorageForm = ({ button }) => {
  const { t } = useTranslation(['common', 'validation']);
  const { uuid, action, type } = useParams();
  const { goBack } = useHistory();
  const isCreate = action === 'new';

  const dispatch = useDispatch();
  const {
    vod_profiles,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const profiles = vod_profiles[type + 's'];
  const currentProfile = profiles?.find(({ uuid: id }) => id === uuid);

  const [locationData, setLocationData] = useState({
    name: '',
    type: '',
    base_url: ''
  });
  const [locationError, setLocationError] = useState(undefined);
  const locationType = getLocationKey(locationData?.type);
  const { initialValues, fields, schema } = { ...VodSettingsFormConfigs[type] };
  const initial = isCreate ? initialValues[locationType] : currentProfile;
  const showLocationForm = locationData?.type && initial;

  const schemaLocation = {
    name: Yup.string()
      .max(36, { name: t('validation:too_long') })
      .required({ name: t('validation:required_field') }),
    type: Yup.string()
      .oneOf(LocationTypes[type], { type: `Must choose a ${type} type` })
      .required({ type: t('validation:required_field') })
  };

  const validateData = ({ type, value }) => {
    schemaLocation[type]
      .validate(value)
      .then(_ => setLocationError(prev => ({ ...prev, [type]: '' })))
      .catch(({ errors }) =>
        setLocationError(prev => ({ ...prev, ...errors[0] }))
      );
  };

  const handleChange = ({ currentTarget: input }) => {
    const data = { ...locationData };
    data[input.name] = input.value;
    setLocationData(data);
    validateData({ type: 'name', value: data?.name });
    validateData({ type: 'type', value: data?.type });
  };

  const handleSubmit = async values => {
    const { uuid, name, ...rest } = values;
    const otherProps = { ...rest };

    const locationKey = getLocationKey(locationData?.type);
    delete otherProps['type'];
    delete otherProps[locationKey];

    const isEdit = action === actionTypes.edit;

    const payload = {
      type: type + 's',
      data: {
        ...locationData,
        ...otherProps,
        uuid: isEdit ? uuid : nanoid(),
        [locationKey]: rest[locationKey]
      }
    };

    const response = isEdit
      ? await dispatch(updateVodProfile(payload))
      : await dispatch(createVodProfile(payload));

    if (response['meta']['requestStatus'] === 'fulfilled') {
      goBack();
    }
  };

  const configs = {
    location: {
      data: locationData,
      error: locationError,
      options: LocationTypes[type],
      onChange: handleChange
    },
    formik: {
      initialValues: initial,
      validationSchema: schema[locationType],
      onSubmit: handleSubmit,
      validateOnMount: !isCreate,
      enableReinitialize: true
    },
    button: {
      type: 'submit',
      variant: 'primary',
      className: 'border-0 mt-4'
    }
  };

  useEffect(() => {
    const data = { ...currentProfile };
    let initialValues = isCreate
      ? { name: '', type: '' }
      : { name: data.name, type: data.type };
    if (type === 'destination') {
      initialValues['base_url'] = isCreate ? '' : data.base_url || '';
    }
    setLocationData(initialValues);
  }, []); // eslint-disable-line
  return (
    <>
      {locationType !== 'akamai_multi_ns' ? (
        <Formik {...configs.formik}>
          {({ dirty, isValid }) => (
            <Form>
              <Card className='modal-background dp-01 rounded'>
                <Card.Body className='p-4'>
                  <Subtitle type={type} />
                  <LocationFields {...configs.location} />
                  {showLocationForm && (
                    <>
                      <Subtitle type={type} text='details' />
                      <FormFields fields={fields[locationType]} />
                    </>
                  )}
                </Card.Body>
              </Card>
              <Button
                {...configs.button}
                disabled={
                  isCreate ? !dirty || !isValid || locationError.name : !isValid
                }
              >
                {t(button)}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik {...configs.formik}>
          {({ values, dirty, isValid }) => {
            // const formData = isCreate
            //   ? [1, 2].map(_ => values?.[locationType])
            //   : values?.[locationType];
            const formData = values?.[locationType];
            const formFields = fields[locationType];
            return (
              <Form>
                <Card className='modal-background dp-01 rounded'>
                  <Card.Body className='p-4'>
                    <Subtitle type={type} />
                    <LocationFields {...configs.location} />
                    {showLocationForm && (
                      <>
                        <FieldArray name={locationType}>
                          {({ remove, push }) => (
                            <>
                              <Subtitle
                                type={type}
                                text='details'
                                gutterTop
                                onClick={() => push(initial[locationType][0])}
                                showTooltip={!formData?.length}
                              />
                              <CardGroup className='flex-nowrap overflow-auto gap-3 mb-4'>
                                {formData?.length > 0 &&
                                  formData.map((_, index) => (
                                    <Modal.Dialog
                                      key={index}
                                      className='bg-transparent border-line rounded m-0 mb-1 col-3'
                                    >
                                      <Modal.Header
                                        className='align-items-start'
                                        onHide={() => remove(index)}
                                        closeButton
                                        closeVariant={
                                          theme === 'dark' ? 'white' : null
                                        }
                                      >
                                        <Modal.Title className='color-high'>
                                          {'Destination ' + (index + 1)}
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <FormFields
                                          fields={formFields[locationType]}
                                          index={index}
                                        />
                                      </Modal.Body>
                                    </Modal.Dialog>
                                  ))}
                              </CardGroup>
                            </>
                          )}
                        </FieldArray>
                        <FormFields
                          className='my-2'
                          fields={formFields['force_single_part_byte_range']}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
                <Button
                  {...configs.button}
                  disabled={
                    isCreate
                      ? !dirty || !isValid || locationError.name
                      : !isValid
                  }
                >
                  {t(button)}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default WithLoader(VodStorageForm);
