import i18n from 'config/i18next';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import Login from 'components/Login';

const LoginPage = () => {
  const { isAuthenticated, settings, loading } = useSelector(({ user }) => user);

  if (isAuthenticated) {
    const type = settings?.hide_getting_started ? 'live' : 'start';
    return <Redirect to={`/${i18n.language}/${type}`} />;
  }

  return <Login loading={loading} fullscreen />;
};

export default LoginPage;
