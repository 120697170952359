import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setHideRecordList } from 'store/live2vod/live2vodSlice';
import { listActiveLive2VODRecords } from 'store/live2vod/live2vodThunk';
import { renderLive2VODStatusIcon } from 'utils/liveUtils';

import CloseButton from 'react-bootstrap/CloseButton';
import CopyButton from 'components/CopyButton';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Live2VODRecordList = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector(({ user }) => user.settings);
  const { active } = useSelector(({ live2vod }) => live2vod);
  const { stack_uuid, names, records } = { ...active };

  useEffect(() => {
    if (!stack_uuid) return;

    const fetchRecords = setInterval(() => {
      dispatch(listActiveLive2VODRecords({ stack_uuid, names }));
    }, 3000);

    const hasIncomplete = records.find(({ stack_uuid }) => stack_uuid);
    if (!hasIncomplete) clearInterval(fetchRecords);
    return () => clearInterval(fetchRecords);
  }, [stack_uuid, names, records, dispatch]);

  return (
    <section
      className='position-fixed bottom-0 end-0 modal-background border-line rounded m-3 dp-02 z-10'
      style={{ width: '24rem' }}
    >
      <div className='d-flex justify-content-between align-items-center underline py-2 px-3'>
        <div className='h6 color-high m-0'>VoD manifest</div>
        <CloseButton
          variant={theme === 'dark' ? 'white' : null}
          onClick={() => dispatch(setHideRecordList())}
        />
      </div>
      <ListGroup className='h-25 overflow-auto' style={{ maxHeight: '75vh' }}>
        {records.map((record, idx) => (
          <Live2VODRecord
            key={record?.name}
            isLast={records?.length - 1 === idx}
            {...record}
          />
        ))}
      </ListGroup>
    </section>
  );
};

const Live2VODRecord = ({
  name,
  stack_uuid,
  clearvr_manifest_filename,
  hls_manifest_filename,
  access_urls,
  isLast,
  error
}) => {
  const underlineClass = `${
    isLast ? 'border-0' : 'border-line border-top-0 border-start-0 border-end-0'
  } d-flex justify-content-start align-items-center gap-2 bg-transparent rounded-0`;
  const contentWidth = { width: 'calc(100% - 36px)' };
  return (
    <ListGroup.Item className={underlineClass}>
      {renderLive2VODStatusIcon({ stack_uuid, error })}
      <div className='d-flex flex-column' style={contentWidth}>
        <div className='color-high'>
          {name.substr(0, name.lastIndexOf('-'))}
        </div>
        <Content value={hls_manifest_filename} type='File name' />
        <Content value={clearvr_manifest_filename} type='File name' />
        {access_urls?.map(url => (
          <Content key={url} value={url} type='Access URL' />
        ))}
        {error && (
          <Content
            value={error?.m}
            type={dayjs(error?.t).format('HH:mm')}
            isError
          />
        )}
      </div>
    </ListGroup.Item>
  );
};

const Content = ({ type, value, isError }) => {
  if (!value) return null;
  return (
    <div className='d-flex align-items-center gap-2'>
      {isError ? (
        <small className='text-danger'>
          <strong className='me-1'>[{type}]</strong>
          {value}
        </small>
      ) : (
        <>
          <OverlayTrigger overlay={<Tooltip>{value}</Tooltip>}>
            <small className='color-medium text-truncate'>
              <strong className='me-1'>[{type}]</strong>
              {value}
            </small>
          </OverlayTrigger>
          <CopyButton
            className='border-0 p-0 bg-transparent'
            size={18}
            value={value}
          />
        </>
      )}
    </div>
  );
};

export default Live2VODRecordList;
