import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createLiveProfile, updateLiveProfile } from 'store/user/userThunk';

import { Formik, Form } from 'formik';
import { ProfileFormFields } from 'constants/forms';
import TextField from 'components/TextField';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';

const ProfileForm = ({
  className = '',
  initialValues,
  onCancel,
  onConfirm,
  mode
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formFields =
    mode === 'start'
      ? ProfileFormFields['start']
      : ProfileFormFields['settings'];

  const handleSubmit = async profile => {
    const response = initialValues
      ? await dispatch(updateLiveProfile(profile))
      : await dispatch(createLiveProfile(profile));
    if (response['meta']['requestStatus'] === 'fulfilled') {
      onConfirm();
    }
  };

  const handleSubmitCopy = async profile => {
    const response = await dispatch(createLiveProfile(profile));
    if (response['meta']['requestStatus'] === 'fulfilled') {
      onConfirm();
    }
  };

  return (
    <Formik
      initialValues={initialValues || ProfileFormFields.initialValues}
      validationSchema={ProfileFormFields.schema}
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, dirty, isValid }) => (
        <Form className={`d-flex flex-column ${className}`}>
          {formFields.map(({ uuid, className, cols }) => (
            <div key={uuid} className={className}>
              {cols.map(({ uuid, ...col }) => (
                <TextField key={uuid} {...col} />
              ))}
            </div>
          ))}
          <div className='d-flex justify-content-between align-items-center my-4'>
            <EmptyButton onClick={onCancel}>
              {mode === 'start' ? t('back') : t('cancel')}
            </EmptyButton>
            <span className='d-grid gap-4 d-flex'>
              {initialValues && (
                <Button
                  variant='outline-primary'
                  disabled={!dirty || !isValid}
                  onClick={() => handleSubmitCopy(values)}
                >
                  {t('save_as_copy')}
                </Button>
              )}
              <Button
                type='submit'
                className='border-0'
                variant='primary'
                disabled={!dirty || !isValid}
              >
                {initialValues ? t('save') : t('create')}
              </Button>
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
