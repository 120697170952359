import { createSlice } from '@reduxjs/toolkit';
import { getUser, updateUser, deleteVodProfile } from 'store/user/userThunk';
import {
  getJobList,
  getHistoryList,
  getRunningJob,
  getVodEncode,
  scheduleVodEncode,
  cancelVodEncode
} from 'store/vod/vodThunk';

export const initialState = {
  profile: null,
  encode: null,
  job: {
    encodes: [],
    previous_page_token: '',
    next_page_token: ''
  },
  history: {
    encodes: [],
    previous_page_token: '',
    next_page_token: '',
    page_size: 10
  },
  loading: false,
  loading_job: false,
  loading_history: false,
  error: null
};

const vodSlice = createSlice({
  name: 'vod',
  initialState,
  reducers: {
    setVodProfile: (state, { payload }) => {
      state.profile = payload;
    }
  },
  extraReducers: {
    [getUser.fulfilled]: (
      state,
      {
        payload: {
          default_vod_uuid,
          vod_profiles: { encoders }
        }
      }
    ) => {
      if (default_vod_uuid && state?.profile?.uuid === default_vod_uuid) return;
      if (!encoders || !encoders?.length) return;
      state.profile = default_vod_uuid
        ? encoders.find(({ uuid }) => uuid === default_vod_uuid)
        : encoders[encoders?.length - 1];
    },
    [updateUser.fulfilled]: (
      state,
      {
        payload: {
          default_vod_uuid,
          vod_profiles: { encoders }
        }
      }
    ) => {
      if (default_vod_uuid && state?.profile?.uuid === default_vod_uuid) return;
      if (!encoders || !encoders?.length) return;
      state.profile = default_vod_uuid
        ? encoders.find(({ uuid }) => uuid === default_vod_uuid)
        : encoders[encoders?.length - 1];
    },
    [deleteVodProfile.fulfilled]: (
      state,
      {
        payload: {
          default_vod_uuid,
          vod_profiles: { encoders }
        }
      }
    ) => {
      if (state.default_vod_uuid !== default_vod_uuid) return;
      if (!encoders || !encoders?.length) state.profile = null;
      else state.profile = encoders[encoders?.length - 1];
      state.job = {
        encodes: [],
        previous_page_token: '',
        next_page_token: ''
      };
      state.history = {
        encodes: [],
        previous_page_token: '',
        next_page_token: ''
      };
    },
    [getJobList.pending]: state => {
      state.loading_job = true;
      state.error = null;
    },
    [getJobList.fulfilled]: (state, { payload }) => {
      state.job = payload;
      state.loading_job = false;
    },
    [getJobList.rejected]: (state, { error }) => {
      state.job = null;
      state.loading_job = false;
      state.error = error.message;
    },
    [getRunningJob.pending]: state => {
      state.error = null;
    },
    [getRunningJob.fulfilled]: (state, { payload }) => {
      state.job = payload;
    },
    [getRunningJob.rejected]: (state, { error }) => {
      state.error = error.message;
    },
    [getHistoryList.pending]: state => {
      state.loading_history = true;
      state.error = null;
    },
    [getHistoryList.fulfilled]: (state, { payload }) => {
      state.history = payload;
      state.loading_history = false;
    },
    [getHistoryList.rejected]: (state, { error }) => {
      state.history = null;
      state.loading_history = false;
      state.error = error.message;
    },
    [getVodEncode.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [getVodEncode.fulfilled]: (state, { payload }) => {
      const { cluster, debug, advanced, ...encode } = {
        ...payload.encode_config
      };
      state.encode = encode;
      state.loading = false;
      state.error = null;
    },
    [getVodEncode.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [scheduleVodEncode.pending]: state => {
      state.loading_job = true;
      state.error = null;
    },
    [scheduleVodEncode.fulfilled]: (state, { payload }) => {
      state.profile.encode_uuid = payload.encode_uuid;
      state.loading_job = false;
    },
    [scheduleVodEncode.rejected]: (state, { error }) => {
      state.loading_job = false;
      state.error = error.message;
    },
    [cancelVodEncode.pending]: state => {
      state.loading_job = true;
      state.error = null;
    },
    [cancelVodEncode.fulfilled]: (state, { payload }) => {
      state.job.encodes = state.job?.encodes.filter(
        ({ encode_uuid }) => encode_uuid !== payload
      );
      state.loading_job = false;
    },
    [cancelVodEncode.rejected]: (state, { error }) => {
      state.loading_job = false;
      state.error = error.message;
    }
  }
});

export const { setVodProfile } = vodSlice.actions;

export default vodSlice.reducer;
