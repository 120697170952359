import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { createLiveProfile, updateLiveProfile } from 'store/user/userThunk';
import { setProfile } from 'store/live/liveSlice';
import { actionTypes } from 'constants/live';
import { populateProfileValues } from 'utils/utils';
import { ProfileFormFields } from 'constants/forms';

import TextField from 'components/TextField';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import Loader from 'components/Loader';

const LiveFormPage = () => {
  const { t } = useTranslation(['common', 'validation']);
  const { push } = useHistory();
  const params = useParams();

  const dispatch = useDispatch();
  const { live_profiles, loading } = useSelector(({ user }) => user);
  const { profile_config } = useSelector(({ live }) => live);

  const currentProfile = live_profiles?.find(({ uuid }) => uuid === params.uuid);
  const formFields = ProfileFormFields['settings'];
  const isCreate = params.action === 'new';
  const isEdit = params.action === actionTypes.edit;
  const isDuplicate = params.action === actionTypes.duplicate;

  const text = isCreate
    ? { title: 'modal_add_profile_title', button: 'create' }
    : isDuplicate
    ? { title: 'Duplicate profile', button: 'save_as_copy' }
    : { title: 'modal_edit_profile_title', button: 'save' };

  const initialValues = isCreate
    ? ProfileFormFields.initialValues
    : populateProfileValues(currentProfile);

  const handleSubmit = async values => {
    const isDefaultProfile = values.uuid === profile_config?.uuid;
    if (isEdit && isDefaultProfile) {
      dispatch(setProfile(values));
    }
    const response =
      isCreate || isDuplicate
        ? await dispatch(createLiveProfile(values))
        : await dispatch(updateLiveProfile(values));
    if (response['meta']['requestStatus'] === 'fulfilled') {
      handleRedirect();
    }
  };
  const handleRedirect = () => {
    push(`/${params.lng}/settings/${params.platform}`);
  };

  if (loading) return <Loader fullscreen />;

  return (
    <main className='main-container my-4'>
      <div className='col-12 col-lg-8 col-xl-6'>
        <span className='d-flex justify-content-between align-items-center mb-4'>
          <h1 className='h3 color-high m-0'>{t(text.title)}</h1>
          <EmptyButton onClick={handleRedirect}>{t('cancel')}</EmptyButton>
        </span>
        <Formik
          initialValues={initialValues}
          validationSchema={ProfileFormFields.schema}
          onSubmit={values => handleSubmit(values)}
          enableReinitialize
        >
          {({ dirty, isValid }) => (
            <Form>
              <Card className='modal-background dp-01 rounded'>
                <Card.Body className='p-4'>
                  <Card.Title as='h4' className='color-high mb-4'>
                    {t('config')}
                  </Card.Title>
                  {formFields.map(({ uuid, className, cols }) => (
                    <div key={uuid} className={className}>
                      {cols.map(({ uuid, ...col }) => (
                        <TextField key={uuid} {...col} />
                      ))}
                    </div>
                  ))}
                </Card.Body>
              </Card>
              <Button
                type='submit'
                variant='primary'
                className='border-0 shadow-none mt-4'
                disabled={!dirty || !isValid}
              >
                {t(text.button)}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
};

export default LiveFormPage;
