import * as Yup from 'yup';
import { nanoid } from '@reduxjs/toolkit';
import { FisheyeProjectionTypes } from 'constants/live';
import {
  PROJECTION_TYPES,
  RATE_CONTROL,
  PACKAGING_FORMAT_DEFAULT,
  PACKAGING_FORMATS
} from 'constants/vod';
import {
  profileLabels,
  defaultRateControl,
  custom_bitrate_schema
} from 'constants/forms';
import { getIngestKey, getIngestFolder } from 'utils/vodUtils';

/**
 * VOD - START FROM
 */

const initialValues = {
  name: '',
  comment: '',
  drm: { type: 'none', name: '', override_content_id: '' },
  advanced: { accept_input_failures: false },
  source: {
    ingest: { uuid: '' },
    video: {
      src_file: '', // source
      projection_type: '', // source
      fish_eye_params: { camera_lens_enum: '' } // source
    }
  },
  encoding_v3: [
    {
      packaging_format: PACKAGING_FORMAT_DEFAULT,
      output_folder: '',
      video: {
        bundles: [
          {
            ...defaultRateControl,
            // rate_control_preset: 'bitrate-control-low', // rate control
            // rate_control_custom_bitrate_in_kbps: 0, // rate control
            // rate_control_custom_crf: 0, // rate control
            // groups: [{ representations: [{}] }],
            optimized_for_decoder_level: 'hevc.5.1', // target decoders
            down_conversion_factor: 0, // encoder (adv) framerate conversion
            target_fov_in_degrees: {
              v_fov_in_deg: 0,
              h_fov_in_deg: 0
            },
            scale_factor: 0
          }
        ],
        gaussian_sharpness_filter: 0 // encoder - sharpness
      },
      auto_add_stereo_downmix: false,
      auto_audio_kbps_per_channel: 64
    }
  ],
  output: {
    uuid: '',
    folder: '',
    file_size_limit: null,
    url: ''
  },
  notification: { type: 'none', target: '' }
};

const typeErrors = {
  source: [
    'source.ingest.uuid',
    'source.video.src_file',
    'source.video.projection_type',
    'source.video.fish_eye_params.camera_lens_enum'
  ],
  destination: ['output.uuid', 'name', 'output.url']
};

// encoding_v3[0].video.bundles[0].down_conversion_factor;
const schema_encoding_v3 = Yup.array().of(
  Yup.object().shape({
    packaging_format: Yup.string().oneOf(PACKAGING_FORMATS),
    video: Yup.object().shape({
      gaussian_sharpness_filter: Yup.number()
        .required('must_between_0_199')
        .typeError('must_be_number')
        .integer()
        .lessThan(200, 'must_less_200')
        .min(0, 'must_greater_equal_0'),
      bundles: Yup.array().of(
        Yup.object().shape({
          optimized_for_decoder_level: Yup.string()
            .oneOf(['hevc.5.1', 'hevc.6.1'])
            .required('required_field'),
          constant: Yup.string().oneOf(RATE_CONTROL['constant']),
          // preset: Yup.string().oneOf(RATE_CONTROL['preset']),
          custom_bitrate: custom_bitrate_schema,
          down_conversion_factor: Yup.number()
            .required('Must between 0 and 10')
            .typeError('must_be_number')
            .integer()
            .min(0, 'must_greater_equal_0')
            .max(10, 'The maximum value is 10'),
          target_fov_in_degrees: Yup.object().shape({
            v_fov_in_deg: Yup.number()
              .integer()
              .min(0, 'must_greater_equal_0')
              .max(180, 'The maximum value is 180'),
            h_fov_in_deg: Yup.number()
              .integer()
              .min(0, 'must_greater_equal_0')
              .max(360, 'The maximum value is 360')
          }),
          scale_factor: Yup.number()
            .min(0, 'The minimum value is 0')
            .max(100, 'The maximum value is 100')
        })
      )
    })
  })
);

export const VodInputResolution = {
  initialValues: {
    width: 0,
    target_width: 0
  },
  validationSchema: Yup.object().shape({
    width: Yup.number()
      .integer()
      .test(
        'is-invalid',
        'Must be between 1024 and 16384',
        value => value === 0 || (value >= 1024 && value <= 16384)
      ),
    target_width: Yup.number().when('width', {
      is: width => width >= 1024,
      then: Yup.number()
        .integer()
        .lessThan(Yup.ref('width'), 'Must be less than input width')
        .min(1024, 'The minimum value is 1024')
    })
  })
};

const schema_output = Yup.object().shape({
  uuid: Yup.string().required('required_field'),
  url: Yup.string().when(['uuid', 'type'], {
    is: (uuid, type) => uuid === 'http-put' || type === 'http-put',
    then: Yup.string().url('must_valid_URL').required('required_field')
  })
});

const schema_video = Yup.object().shape({
  src_file: Yup.string().trim().required('required_field'),
  projection_type: Yup.string()
    .oneOf(PROJECTION_TYPES.slice(1), 'required_field')
    .required('required_field'),
  fish_eye_params: Yup.object().when('projection_type', {
    is: type => FisheyeProjectionTypes.includes(type),
    then: Yup.object().shape({
      camera_lens_enum: Yup.string()
        // .oneOf(FisheyeCameraLens.slice(1), 'required_field')
        .required('must_choose_lens')
    })
  })
});

const schema_source = Yup.object().shape({
  ingest: Yup.object().shape({
    uuid: Yup.string().required('required_field')
  }),
  video: schema_video
});

const schema_drm = Yup.object().shape({
  type: Yup.string()
    .oneOf(['none', 'customer-default', 'by-name'])
    .required('required_field')
});

const schema_notification = Yup.object().shape({
  target: Yup.string()
    .when('type', {
      is: type => type === 'mail',
      then: Yup.string().email('Must be a valid email').required('required_field')
    })
    .when('type', {
      is: type => type === 'webhook',
      then: Yup.string().url('Must be a valid URL').required('required_field')
    })
});

const schema_name = Yup.string()
  .min(2, 'too_short')
  .max(80, 'too_long')
  .matches(/^[a-zA-Z0-9_\-.~]+$/, 'must_not_contain_spaces')
  .required('required_field');

const typeSchema = {
  source: Yup.object().shape({
    source: schema_source
  }),
  encoder: Yup.object().shape({
    encoding_v3: schema_encoding_v3,
    drm: schema_drm
  }),
  destination: Yup.object().shape({
    output: schema_output,
    name: schema_name,
    notification: schema_notification
  })
};

const schema = Yup.object().shape({
  name: schema_name,
  source: schema_source,
  encoding_v3: schema_encoding_v3,
  output: schema_output,
  drm: schema_drm,
  notification: schema_notification
});

const templateInitialValues = {
  name: '',
  template_publication_folder: '',
  comment: '',
  source: {
    multi_video: [
      {
        name: '',
        src_file: '',
        projection_type: 'rectilinear',
        fish_eye_params: { camera_lens_enum: '' }
      }
    ]
  }
};

const invalid_text = 'Invalid value, contact Tiledmedia';

const schema_location_string = Yup.string()
  .required(invalid_text)
  .test('is-not-empty', invalid_text, value => value !== '')
  .test('is-not-null', invalid_text, value => value !== 'null');

const schema_location_obj = Yup.lazy(value => {
  const { type } = { ...value };
  const key = getIngestKey(type);
  const folder = getIngestFolder(type);

  const schema = { type: schema_location_string };
  if (folder) {
    schema[key] = Yup.object().shape({
      [folder]: schema_location_string
    });
  }
  return Yup.object().shape(schema);
});

const schema_videos = {
  src_file: Yup.string().trim().required('required_field'),
  projection_type: Yup.string()
    .oneOf(PROJECTION_TYPES.slice(1), 'required_field')
    .required('required_field'),
  fish_eye_params: Yup.object().when('projection_type', {
    is: type => FisheyeProjectionTypes.includes(type),
    then: Yup.object().shape({
      camera_lens_enum: Yup.string()
        // .oneOf(FisheyeCameraLens.slice(1), 'required_field')
        .required('must_choose_lens')
    })
  })
};

const schema_videos_with_name = {
  name: schema_name,
  ...schema_videos
};

const schema_template_source = Yup.object().shape({
  ingest: schema_location_obj,
  multi_video: Yup.array()
    .of(Yup.object().shape(schema_videos))
    .min(1, 'Video source is required')
});

const schema_template_egress = Yup.array().of(schema_location_obj);

const template_publication_folder = Yup.string()
  .required('required_field')
  .max(200, 'too_long')
  .matches(/^[a-zA-Z0-9_\-.~]+$/, 'must_not_contain_spaces');

const tempSchema = {
  source: Yup.object().shape({
    source: schema_template_source
  }),
  destination: Yup.object().shape({
    egress: schema_template_egress,
    name: schema_name,
    template_publication_folder
  })
};

const templateSchema = Yup.object().shape({
  name: schema_name,
  template_publication_folder,
  source: schema_template_source,
  egress: schema_template_egress,
  notification: schema_notification
});

const templateSchemaWithName = Yup.object().shape({
  name: schema_name,
  template_publication_folder,
  source: Yup.object().shape({
    ingest: schema_location_obj,
    multi_video: Yup.array()
      .of(Yup.object().shape(schema_videos_with_name))
      .min(1, 'Video source is required')
  }),
  egress: Yup.array().of(schema_location_obj),
  notification: schema_notification
});

const initialABR = {
  constant: 'bitrate',
  preset: 'default',
  custom_bitrate: 512,
  optimized_for_decoder_level: 'hevc.5.1', // target decoders
  down_conversion_factor: 0, // encoder (adv) framerate conversion
  target_fov_in_degrees: {
    v_fov_in_deg: 0,
    h_fov_in_deg: 0
  },
  scale_factor: 0
};

export const sourceField = {
  uuid: nanoid(),
  className: 'col-12',
  name: 'source.video.src_file',
  // label: 'Source file'
  validation: true
};

export const projectionField = {
  uuid: nanoid(),
  className: 'col-12 col-lg-5',
  name: 'source.video.projection_type',
  label: 'projection_type',
  options: PROJECTION_TYPES,
  validation: true
};

const sharpnessField = {
  uuid: nanoid(),
  type: 'number',
  className: 'col-6 col-lg-4',
  name: 'encoding_v3.[0].video.gaussian_sharpness_filter',
  label: 'Sharpness',
  helpMessage: 'hint_sharpness',
  validation: true
};

const decoderFields = {
  uuid: nanoid(),
  name: 'optimized_for_decoder_level',
  label: 'Target decoder',
  options: ['Choose a decoder', 'hevc.5.1', 'hevc.6.1'],
  validation: true
};

// crf-control-custom      (0 - 51; 512 - ? Kbps)
// bitrate-control-custom  (512 - ? Kbps)
const constant = { name: 'constant', label: 'Constant' };
const preset = { name: 'preset', label: 'Preset' };
const custom_bitrate = {
  name: 'custom_bitrate',
  label: 'Bitrate',
  type: 'number',
  unit: 'kbps',
  // helpMessage: 'Minimum 512 or 0 (auto)',
  validation: true
};
const rateControlFields = { constant, preset, custom_bitrate };

export const VodStartFormConfigs = {
  initialValues,
  initialABR,
  schema,
  typeSchema,
  tempSchema,
  typeErrors,
  fields: {
    source: sourceField,
    projection: projectionField,
    sharpness: sharpnessField,
    decoder: decoderFields,
    rateControl: rateControlFields
  }
};

export const VodTemplateFormConfigs = {
  initialValues: templateInitialValues,
  schema: templateSchema,
  schemaWithName: templateSchemaWithName,
  fields: {
    source: sourceField,
    projection: projectionField
  }
};

export const VodEncodeConfigFormConfigs = {
  initialValues: { encode_config: '' },
  validationSchema: Yup.object().shape({
    encode_config: Yup.string().required('required_field')
  })
};

/**
 * VOD - ENCODER FORM
 */

const encoderValues = {
  name: '',
  customer_entrypoint: '',
  customer_id: '', // integer
  api_auth_token: '',
  drm: { type: 'none' },
  notification: { type: 'none', target: '' }
};

const encoderSchema = Yup.object().shape({
  name: Yup.string().min(2, 'too_short').max(30, 'too_long').required('required_field'),
  // customer_entrypoint: Yup.string().url('must_valid_URL'),
  customer_id: Yup.number()
    .typeError('must_be_number')
    .integer()
    .positive()
    .required('required_field'),
  api_auth_token: Yup.string().required('required_field'),
  notification: schema_notification
  // drm: { type: 'none' },
});

const encoderFields = [
  {
    uuid: nanoid(),
    className: 'col-12 col-md-8 col-lg-6',
    name: 'name',
    label: profileLabels['name'],
    autoFocus: true,
    validation: true
  },
  {
    uuid: nanoid(),
    className: 'col',
    name: 'customer_entrypoint',
    label: profileLabels['customer_entrypoint'],
    placeholder: 'hint_provided_only'
    // validation: true
  },
  {
    uuid: nanoid(),
    className: 'row',
    cols: [
      {
        uuid: nanoid(),
        type: 'number',
        className: 'col-12 col-md-3',
        name: 'customer_id',
        label: profileLabels['customer_id'],
        validation: true,
        placeholder: 'hint_provided_only'
      },
      {
        uuid: nanoid(),
        className: 'col',
        type: 'password',
        autoComplete: 'new-password',
        name: 'api_auth_token',
        label: profileLabels['api_auth_token'],
        validation: true,
        placeholder: 'hint_provided_only'
      }
    ]
  },
  {
    uuid: nanoid(),
    className: 'col-4',
    name: 'drm.type',
    label: 'DRM',
    options: ['Select a type', 'none', 'customer default']
  },
  {
    uuid: nanoid(),
    className: 'row mt-4',
    cols: [
      {
        uuid: nanoid(),
        name: 'notification.type',
        className: 'col-4',
        label: 'Notification',
        options: ['none', 'mail', 'webhook']
      },
      {
        uuid: nanoid(),
        name: 'notification.target',
        className: 'col-8',
        validation: true
      }
    ]
  }
];

/**
 * VOD - CLOUD STORAGE
 */

const azure_blob = {
  values: { account_name: '', account_key: '', container: '' },
  schema: Yup.object().shape({
    azure_blob: Yup.object().shape({
      account_name: Yup.string().required('required_field'),
      account_key: Yup.string().required('required_field'),
      container: Yup.string().required('required_field')
    })
  }),
  fields: [
    {
      uuid: nanoid(),
      className: 'col-12 col-md-8 col-lg-6',
      name: 'azure_blob.account_name',
      label: 'Account name',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      type: 'password',
      autoComplete: 'new-password',
      name: 'azure_blob.account_key',
      label: 'Account key',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 'azure_blob.container',
      label: 'Container',
      helpMessage: 'azure_container',
      validation: true
    }
  ]
};

const s3 = {
  values: {
    region: '',
    custom_endpoint: '',
    bucket: '',
    access_key_id: '',
    secret_access_key: '',
    default_acl: ''
  },
  schema: Yup.object().shape({
    s3: Yup.object().shape({
      region: Yup.string().required('required_field'),
      bucket: Yup.string()
        .min(2, 'too_short')
        // .max(20, 'too_long')
        .required('required_field'),
      access_key_id: Yup.string().required('required_field'),
      secret_access_key: Yup.string().required('required_field')
    })
  })
};

const akamai = {
  values: { host: '', keyname: '', key: '', cpcode: '', path: '' },
  schema: Yup.object().shape({
    host: Yup.string().required('required_field'),
    keyname: Yup.string().required('required_field'),
    key: Yup.string().required('required_field'),
    cpcode: Yup.string().required('required_field'),
    path: Yup.string().required('required_field')
  }),
  fields: [
    {
      uuid: nanoid(),
      className: 'col',
      name: 'akamai_ns.host',
      label: 'Host',
      helpMessage: 'akamai_host',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-3',
          name: 'akamai_ns.keyname',
          label: 'Keyname',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col',
          name: 'akamai_ns.key',
          label: 'Key',
          validation: true
        }
      ]
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-3',
          name: 'akamai_ns.cpcode',
          label: 'CP code',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col',
          name: 'akamai_ns.path',
          label: 'Path',
          validation: true
        }
      ]
    }
  ]
};

const http = {
  values: { url: '' },
  schema: Yup.object().shape({
    url: Yup.string().url('must_valid_URL').required('required_field')
  })
};

/**
 * VOD - SOURCE FORM
 */

const sourceValues = {
  s3: { s3: s3.values },
  azure_blob: { azure_blob: azure_blob.values },
  gcp_storage: {
    gcp_storage: { bucket: '', service_account_credentials_b64: '' }
  },
  aspera: { aspera: { username: '', password: '', host: '' } },
  http_pull: { http_pull: http.values }
};

const sourceSchema = {
  s3: s3.schema,
  azure_blob: azure_blob.schema,
  gcp_storage: Yup.object().shape({
    gcp_storage: Yup.object().shape({
      bucket: Yup.string().required('required_field')
    })
  }),
  aspera: Yup.object().shape({
    aspera: Yup.object().shape({
      username: Yup.string().required('required_field'),
      password: Yup.string().required('required_field'),
      host: Yup.string().required('required_field')
    })
  }),
  http_pull: http.schema
};

const sourceFields = {
  s3: [
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-12 col-md-4',
          name: 's3.region',
          label: 'Region',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col',
          name: 's3.bucket',
          label: 'Bucket name',
          validation: true
        }
      ]
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 's3.access_key_id',
      label: 'Access key ID',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      type: 'password',
      autoComplete: 'new-password',
      name: 's3.secret_access_key',
      label: 'Secret access key',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 's3.custom_endpoint',
      label: 'Custom endpoint'
      // validation: true,
    }
    // {
    //   default_acl: ''
    // }
  ],
  azure_blob: azure_blob.fields,
  gcp_storage: [
    {
      uuid: nanoid(),
      className: 'col-12 col-md-8 col-lg-6',
      name: 'gcp_storage.bucket',
      label: 'Bucket name',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col mb-4',
      name: 'gcp_storage.service_account_credentials_b64',
      label: 'Service account credentials',
      rows: '5'
    }
  ],
  aspera: [
    {
      uuid: nanoid(),
      className: 'col-12 col-md-8 col-lg-6',
      name: 'aspera.username',
      label: 'Username',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      type: 'password',
      autoComplete: 'new-password',
      name: 'aspera.password',
      label: 'Password',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 'aspera.host',
      label: 'Host',
      validation: true
    }
  ],
  http_pull: [
    {
      uuid: nanoid(),
      className: 'col-12',
      name: 'http_pull.url',
      label: 'URL',
      validation: true
    }
  ]
};

/**
 * VOD - COMMON FIELDS
 */

const force_single = {
  value: {
    force_single_part_byte_range: false
  },
  field: {
    uuid: nanoid(),
    className: 'col',
    type: 'checkbox',
    name: 'force_single_part_byte_range',
    label: 'Force single part range requests'
  }
};

const file_size_limit = {
  value: { file_size_limit: 0 },
  field: {
    uuid: nanoid(),
    className: 'col-3',
    type: 'number',
    name: 'file_size_limit',
    label: 'Max file size',
    unit: 'Mb'
  }
};

const folder = {
  value: { folder: '' },
  field: {
    uuid: nanoid(),
    className: 'col-4',
    name: 'folder',
    label: 'Subfolder'
  }
};

/**
 * VOD - DESTINATION FORM
 */

const destinationValues = {
  s3: {
    s3: s3.values,
    file_size_limit: 0,
    folder: '',
    ...force_single.value
  },
  azure_blob: { azure_blob: azure_blob.values, ...force_single.value },
  aws_mediastore: {
    aws_mediastore: {
      region: '',
      container_endpoint: '',
      access_key_id: '',
      secret_access_key: ''
    },
    ...force_single.value
  },
  wangsu: { wangsu: { host: '', token: '', path: '' }, ...force_single.value },
  akamai_ns: { akamai_ns: akamai.values, ...force_single.value },
  akamai_multi_ns: {
    akamai_multi_ns: [akamai.values],
    ...force_single.value
  },
  http_put: { http_put: http.values, ...force_single.value }
};

const destinationSchema = {
  s3: s3.schema,
  azure_blob: azure_blob.schema,
  aws_mediastore: Yup.object().shape({
    aws_mediastore: Yup.object().shape({
      region: Yup.string().required('required_field'),
      container_endpoint: Yup.string().required('required_field'),
      access_key_id: Yup.string().required('required_field'),
      secret_access_key: Yup.string().required('required_field')
    })
  }),
  wangsu: Yup.object().shape({
    wangsu: Yup.object().shape({
      host: Yup.string().required('required_field'),
      token: Yup.string().required('required_field'),
      path: Yup.string().required('required_field')
    })
  }),
  akamai_ns: Yup.object().shape({ akamai_ns: akamai.schema }),
  akamai_multi_ns: Yup.object().shape({
    akamai_multi_ns: Yup.array().of(akamai.schema)
  }),
  http_put: Yup.object().shape({ http_put: http.schema })
};

const destinationFields = {
  s3: [
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-12 col-md-4',
          name: 's3.region',
          label: 'Region',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col',
          name: 's3.bucket',
          label: 'Bucket name',
          validation: true
        }
      ]
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col',
          name: 's3.access_key_id',
          label: 'Access key ID',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col',
          type: 'password',
          autoComplete: 'new-password',
          name: 's3.secret_access_key',
          label: 'Secret access key',
          validation: true
        }
      ]
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        folder.field,
        {
          uuid: nanoid(),
          className: 'col',
          name: 's3.custom_endpoint',
          label: 'Custom endpoint'
          // validation: true,
        }
      ]
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-3',
          name: 's3.default_acl',
          label: 'Default ACL'
          // validation: true,
        },
        file_size_limit.field
      ]
    },
    force_single.field
  ],
  azure_blob: [...azure_blob.fields, { ...force_single.field, className: 'mt-3' }],
  aws_mediastore: [
    {
      uuid: nanoid(),
      className: 'col-12 col-md-5',
      name: 'aws_mediastore.region',
      label: 'Region',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 'aws_mediastore.container_endpoint',
      label: 'Container endpoint',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'row',
      cols: [
        {
          uuid: nanoid(),
          className: 'col-5',
          name: 'aws_mediastore.access_key_id',
          label: 'Access key ID',
          validation: true
        },
        {
          uuid: nanoid(),
          className: 'col-7',
          type: 'password',
          autoComplete: 'new-password',
          name: 'aws_mediastore.secret_access_key',
          label: 'Secret access key',
          validation: true
        }
      ]
    },
    force_single.field
  ],
  wangsu: [
    {
      uuid: nanoid(),
      className: 'col',
      name: 'wangsu.host',
      label: 'Host',
      validation: true
    },
    {
      uuid: nanoid(),
      className: 'col mb-4',
      name: 'wangsu.token',
      label: 'Token',
      validation: true,
      rows: '3'
    },
    {
      uuid: nanoid(),
      className: 'col',
      name: 'wangsu.path',
      label: 'Path',
      validation: true
    },
    force_single.field
  ],
  akamai_ns: [...akamai.fields, force_single.field],
  akamai_multi_ns: {
    akamai_multi_ns: [
      {
        uuid: nanoid(),
        className: 'col',
        name: 'host',
        label: 'Host',
        helpMessage: 'akamai_host',
        validation: true
      },
      {
        uuid: nanoid(),
        className: 'row',
        cols: [
          {
            uuid: nanoid(),
            className: 'col-3',
            name: 'keyname',
            label: 'Keyname',
            validation: true
          },
          {
            uuid: nanoid(),
            className: 'col',
            name: 'key',
            label: 'Key',
            validation: true
          }
        ]
      },
      {
        uuid: nanoid(),
        className: 'row',
        cols: [
          {
            uuid: nanoid(),
            className: 'col-3',
            name: 'cpcode',
            label: 'CP code',
            validation: true
          },
          {
            uuid: nanoid(),
            className: 'col',
            name: 'path',
            label: 'Path',
            validation: true
          }
        ]
      }
    ],
    force_single_part_byte_range: [force_single.field]
  },
  http_put: [
    {
      uuid: nanoid(),
      className: 'col-12',
      name: 'http_put.url',
      label: 'URL',
      validation: true
    },
    force_single.field
  ]
};

export const VodSettingsFormConfigs = {
  encoder: {
    initialValues: encoderValues,
    schema: encoderSchema,
    fields: encoderFields
  },
  source: {
    initialValues: sourceValues,
    schema: sourceSchema,
    fields: sourceFields
  },
  destination: {
    initialValues: destinationValues,
    schema: destinationSchema,
    fields: destinationFields
  }
};
