import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useField } from 'formik';
import { capitalizeString } from 'utils/utils';
import { DISABLED_TYPES } from 'constants/vod';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AlertIcon from 'remixicon-react/AlertFillIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import CopyButton from 'components/CopyButton';

const TextField = ({
  name,
  label,
  helpMessage,
  helpContent,
  className = '',
  ...props
}) => {
  const { t } = useTranslation('validation');
  const [, meta] = useField(name);

  if (props.type === 'checkbox')
    return <CheckboxField name={name} label={label} className={className} />;
  return (
    <div className={`${props?.size ? '' : 'input-height'} ${className}`}>
      {label && (
        <label htmlFor={name} className='form-label color-medium'>
          {t(label)}
        </label>
      )}
      <InputField name={name} label={label} {...props} />
      {!meta?.error && (helpMessage || helpContent) && (
        <div className='form-text w-100 d-block color-disabled'>
          {helpContent || t(helpMessage)}
        </div>
      )}
    </div>
  );
};

const CheckboxField = ({ name, label, className = '' }) => {
  const { t } = useTranslation('validation');
  const [field] = useField(name);
  const { value, ...rest } = field;
  const showTooltip = field.name === 'force_single_part_byte_range' && field.value;
  return (
    <div className={`form-check ${className}`} style={{ height: 25 }}>
      <input
        className='form-check-input'
        type='checkbox'
        id={name}
        name={name}
        checked={field.value}
        {...rest}
      />
      <label className='form-check-label color-medium' htmlFor={name}>
        {t(label)}
      </label>
      {showTooltip && (
        <OverlayTrigger
          placement='auto-start'
          overlay={
            <Tooltip id='force-single-tooltip'>
              Only select if your CDN does not support multipart/byte range requests
            </Tooltip>
          }
        >
          <div className='fade-in ms-2 d-inline-block'>
            <AlertIcon className='color-medium' />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

const InputField = ({ name, validation, options, children, ...props }) => {
  const { t } = useTranslation('validation');
  const { prepend, type, copy, unit, placeholder, size, validateOnMount, ...rest } =
    props;
  const [field, meta, helpers] = useField(name);
  const [srcError, setSrcError] = useState(rest?.error); // for 'source.video.src_file'
  const [isPasswordType, setIsPasswordType] = useState(type === 'password');
  const showOptionPlaceholder =
    !name.endsWith('constant') &&
    !name.endsWith('preset') &&
    !name.endsWith('packaging_format');

  const setValidStyles = ({ touched, error }) => {
    if (!validation || !touched) return 'border-line';
    return error || srcError ? 'is-invalid' : 'is-valid';
  };

  const handleWheel = event => {
    if (type !== 'number') return;
    event.currentTarget.blur();
  };

  const parseOption = option => {
    if (name.endsWith('optimized_for_decoder_level')) {
      const lastIndex = option.length;
      const decoder = option.substring(lastIndex - 3, lastIndex);
      return `HEVC level ${decoder}`;
    }
    switch (option) {
      case 'tm31-manifest-v7':
        return 'v8.x and higher (Default)';
      case 'tm32-manifest-v7':
        return 'v9.x and higher';
      default:
        return capitalizeString(option);
    }
  };

  useEffect(() => {
    if (!validateOnMount) return; // exclude custom_bitrate field (number)
    if (meta.error) helpers.setTouched({ [name]: true });
    return helpers.setTouched({ [name]: false });
  }, [field.value, meta.error]); // eslint-disable-line

  useEffect(() => {
    if (!field.value || name !== 'source.video.src_file') return;
    setSrcError(rest.error);
  }, [rest.error]); // eslint-disable-line

  return (
    <div
      className={`${
        prepend || unit || copy || children // children: ApplyAll button
          ? `input-group ${size ? `input-group-${size}` : ''}`
          : type === 'password'
          ? 'position-relative'
          : ''
      } ${srcError || meta?.error ? 'has-validation' : ''}`}
    >
      {prepend && (
        <span className='input-group-text unit-input rounded-0 rounded-start text-xs'>
          {prepend}
        </span>
      )}
      {options ? (
        <select
          {...field}
          disabled={rest.disabled}
          className={`form-select ${size ? `form-select-${size}` : ''} ${setValidStyles(
            meta
          )}`}
          {...rest}
        >
          {options.map((option, index) => (
            <Fragment key={option + index}>
              {!index && showOptionPlaceholder && option !== 'none' ? (
                <option value=''>{t(option)}</option>
              ) : DISABLED_TYPES.includes(option) ? (
                <option disabled>{capitalizeString(option)}</option>
              ) : (
                <option value={option}>{parseOption(option)}</option>
              )}
            </Fragment>
          ))}
        </select>
      ) : rest.rows ? (
        <textarea
          className={`form-control ${size ? `form-control-${size}` : ''} ${setValidStyles(
            meta
          )}`}
          placeholder={t(placeholder)}
          ref={rest.focusref}
          {...rest}
          {...field}
        />
      ) : (
        <input
          type={type !== 'password' ? type : isPasswordType ? 'password' : 'text'}
          className={`form-control ${size ? `form-control-${size}` : ''} ${
            copy ? 'rounded-0 rounded-start' : ''
          } ${setValidStyles(meta)} ${type === 'password' ? 'no-checked-icon pe-5' : ''}`}
          onWheel={event => handleWheel(event)}
          placeholder={
            name.endsWith('url') || name.endsWith('src_file')
              ? placeholder
              : t(placeholder)
          }
          aria-label={name}
          ref={rest.focusref}
          {...rest}
          {...field}
        />
      )}
      {type === 'password' && (
        <div
          className='color-medium position-absolute top-0 end-0 z-5 pe-pointer'
          onClick={() => setIsPasswordType(preState => !preState)}
          style={{ margin: '6px 8px' }}
        >
          {isPasswordType ? <EyeLineIcon /> : <EyeOffLineIcon />}
        </div>
      )}
      {children}
      {unit && <span className='input-group-text unit-input'>{unit}</span>}
      {copy && <CopyButton value={field.value} />}
      {size === 'sm' && name.startsWith('source.multi_video') ? null : srcError ? (
        <div className='invalid-feedback'>{srcError}</div>
      ) : (
        <ErrorMessage name={field.name}>
          {message => <div className='invalid-feedback'>{t(message)}</div>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default TextField;
