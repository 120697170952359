import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSnapshot } from 'store/live/liveThunk';
import { switchSnapshot, setSnapshotCurrentIndex } from 'store/live/liveSlice';
import {
  ingestGraphs,
  NoFisheyeProjectionTypes,
  FisheyeProjectionTypes,
  FisheyeCameraLens
} from 'constants/live';

import { formatLocaleTime } from 'utils/utils';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LiveChartBlock from 'components/LiveChartBlock';
import Loader from 'components/Loader';
import RefreshIcon from 'remixicon-react/RefreshLineIcon';

const SnapshotAlert = ({ onClose, className = '', children }) => (
  <div className={`d-flex z-5 fade-in bg-danger text-white rounded ${className}`}>
    <small className='toast-body py-2'>{children}</small>
    <button
      type='button'
      className='btn-close btn-close-white me-2 m-auto'
      data-bs-dismiss='toast'
      aria-label='Close'
      onClick={onClose}
    />
  </div>
);

const LiveCarousel = ({
  stack_uuid,
  timeSelector,
  renderPanel,
  renderTitle,
  isSnapshotAvailable
}) => {
  const { t } = useTranslation(['common', 'glossary']);
  const dispatch = useDispatch();
  const { profile_config, snapshot } = useSelector(({ live }) => live);
  const { multi_video } = useSelector(({ modal }) => modal.content);

  const {
    index: { current: currentIdx, all: indexes },
    img,
    time,
    loading
  } = snapshot;
  const { uuid } = profile_config;
  const isMultiple = indexes?.length > 1;

  const [clicked, setClicked] = useState(false);
  const [showSnapshotAlert, setShowSnapshotAlert] = useState(false);
  const [showBorder, setShowBorder] = useState(false);

  const currentVideo = multi_video[currentIdx];
  const [videoValues, setVideoValues] = useState(currentVideo);
  const isCurrentFisheye = Boolean(currentVideo?.projection_type?.startsWith('fish-eye'));
  const isFisheyeInput = name => name === 'fish_eye_params';

  const handleSelect = (selectedIndex, event) => {
    dispatch(setSnapshotCurrentIndex(selectedIndex));
  };

  const handleSlide = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1500);
  };

  const FisheyeLens = FisheyeCameraLens.slice(1);

  const selectOptions = [
    {
      className: 'col-12 col-lg-4',
      name: 'projection_type',
      label: 'Projection type',
      options: isCurrentFisheye ? FisheyeProjectionTypes : NoFisheyeProjectionTypes
    }
  ];

  if (isCurrentFisheye) {
    selectOptions.push({
      className: 'col-12 col-lg-8',
      name: 'fish_eye_params',
      label: 'Fisheye Camera-lens',
      options: FisheyeLens
    });
  }

  const parseValue = (name, value) => {
    if (isFisheyeInput(name)) return value?.[name]?.['camera_lens_enum'];
    return value?.[name];
  };

  const handleChange = ({ currentTarget: input }) => {
    const data = { ...videoValues };
    data[input.name] = isFisheyeInput(input.name)
      ? { camera_lens_enum: input.value }
      : input.value;
    setVideoValues(data);
  };

  useEffect(() => {
    if (!isSnapshotAvailable) return;

    const payload = {
      stack_uuid,
      index: currentIdx,
      resolution: { height: 224 }
    };

    if (isCurrentFisheye && showBorder) {
      payload['emphasize_projection_border'] = true;
      payload['input_projection'] = videoValues;
    }
    dispatch(fetchSnapshot(payload));
    const streaming = setInterval(() => dispatch(fetchSnapshot(payload)), 5000);
    return () => {
      dispatch(switchSnapshot());
      clearInterval(streaming);
    };
  }, [currentIdx, isCurrentFisheye, showBorder, videoValues, isSnapshotAvailable]); // eslint-disable-line

  useEffect(() => {
    if (!showBorder || !isCurrentFisheye) return setShowSnapshotAlert(false);
    const isSameAsDefault = JSON.stringify(videoValues) !== JSON.stringify(currentVideo);
    setShowSnapshotAlert(isSameAsDefault);
    return () => setShowSnapshotAlert(false);
  }, [currentVideo, videoValues, showBorder, isCurrentFisheye]);

  useEffect(() => {
    if (!showBorder) localStorage.removeItem('input_projection');
    return () => localStorage.removeItem('input_projection');
  }, [showBorder]);

  return (
    <section>
      <div className='d-grid gap-2'>
        <Card className='rounded w-100 bg-transparent border-line overflow-hidden'>
          <Card.Header className='d-flex flex-wrap justify-content-between align-items-center gap-2 underline'>
            <span>
              <span className='me-3'>{renderTitle('snapshot', currentIdx)}</span>
              {isCurrentFisheye && (
                <div className='form-check d-inline-block my-auto'>
                  <input
                    id='show_fisheye_border'
                    name='show_fisheye_border'
                    className='form-check-input'
                    type='checkbox'
                    checked={showBorder}
                    onChange={() => setShowBorder(prevState => !prevState)}
                  />
                  <label
                    htmlFor='show_fisheye_border'
                    className='form-check-label color-medium text-sm user-select-none'
                  >
                    Show calibration overlay
                  </label>
                </div>
              )}
            </span>
            <OverlayTrigger
              placement='bottom-end'
              overlay={<Tooltip>{t('common:refresh_snapshot')}</Tooltip>}
            >
              <small className='state-tabs fw-bolder color-medium d-flex align-items-center m-0 fade-in'>
                {`${t('glossary:local_time')} ${
                  time ? formatLocaleTime(time) : '--:--:--'
                }`}
                <RefreshIcon className='ms-2' size={16} />
              </small>
            </OverlayTrigger>
          </Card.Header>
          {showBorder && isCurrentFisheye && (
            <div className='row m-0 px-1 pb-3 gy-2 gx-4 fade-in'>
              {selectOptions.map(({ name, className, label, options }) => (
                <div key={name} className={className}>
                  <label htmlFor={name} className='form-label color-medium text-sm'>
                    {label}
                  </label>
                  <select
                    id={name}
                    name={name}
                    defaultValue={parseValue(name, videoValues) || options[0]}
                    className='form-select form-select-sm border-line'
                    onChange={handleChange}
                  >
                    {options.map(option => (
                      <option
                        key={option}
                        value={option}
                        className='text-primary'
                        disabled={option.includes(' ')}
                      >
                        {`${option}${
                          option === parseValue(name, currentVideo) ? ' [In use]' : ''
                        }`}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          )}
          <div className='position-relative'>
            {showSnapshotAlert && (
              <SnapshotAlert
                className='position-absolute bottom-0 end-0 m-2'
                onClose={() => setShowSnapshotAlert(false)}
              >
                Adjust transcoder or camera to apply these settings
              </SnapshotAlert>
            )}
            <Carousel
              activeIndex={currentIdx}
              indicators={isMultiple}
              onSelect={handleSelect}
              onSlide={handleSlide}
              className={
                !isMultiple
                  ? 'carousel-hide-icons'
                  : clicked
                  ? 'carousel-disable-icons'
                  : ''
              }
            >
              {indexes?.map(idx => {
                const isLoading = loading && !img;
                const showSnapshot = img && idx === currentIdx && isSnapshotAvailable;
                const imgClasses = `d-block mx-auto w-auto ${
                  isMultiple ? 'mt-4 mb-5' : ''
                } lazy-load`;
                return (
                  <Carousel.Item key={`snapshots ${idx}`}>
                    <section className='carousel-body d-flex justify-content-center align-items-center'>
                      {isLoading ? (
                        <Loader className='carousel-loader' />
                      ) : showSnapshot ? (
                        <OverlayTrigger
                          overlay={<Tooltip>{t('common:open_snapshot')}</Tooltip>}
                        >
                          <Link
                            to={`/snapshot/${uuid}/${stack_uuid}/${currentIdx}`}
                            className={clicked ? 'pe-none' : 'pe-auto'}
                            target='_blank'
                          >
                            <img
                              className={imgClasses}
                              src={`data:image/gif;base64,${img}`}
                              alt={`Snapshot of Source ${idx}`}
                            />
                          </Link>
                        </OverlayTrigger>
                      ) : (
                        <p className='text-white m-0'>
                          {!img && 'No available snapshot'}
                        </p>
                      )}
                    </section>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </Card>
        {indexes?.map(idx => (
          <Fragment key={idx + 'chart'}>
            {idx === currentIdx && (
              <LiveChartBlock
                title={renderTitle('live_status', currentIdx)}
                {...timeSelector}
              >
                {ingestGraphs.map(props => (
                  <div
                    key={props.title + currentIdx}
                    className='col-12 col-lg-6'
                    style={{ height: 232 }}
                  >
                    {renderPanel({ inputIndex: currentIdx, ...props })}
                  </div>
                ))}
              </LiveChartBlock>
            )}
          </Fragment>
        ))}
      </div>
    </section>
  );
};

export default LiveCarousel;
