import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

const EmptyButton = ({
  reversedStyles,
  children,
  className = '',
  ...props
}) => {
  const theme = useSelector(({ user }) => user?.settings?.theme);
  const reversedTheme = theme === 'dark' ? 'light' : 'dark';
  return (
    <Button
      variant={reversedStyles ? reversedTheme : theme}
      className={`${className} color-medium btn-action btn-transition bg-transparent border-0 shadow-none`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default EmptyButton;
