import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import StatusIndicator from 'components/StatusIndicator';
import RefreshIcon from 'remixicon-react/RefreshLineIcon';
import { capitalizeString } from 'utils/utils';

const LiveDetailsNavs = ({
  navItems,
  streamStatus,
  activeKey,
  lastestUpdate
}) => {
  const { t } = useTranslation(['common', 'glossary']);
  return (
    <Nav className='state-tabs underline fw-bolder'>
      {navItems.map(item => {
        const isSelected = activeKey === item;
        const status = streamStatus && streamStatus[item];
        const hasError = status === 'red';
        const hasWarning = status === 'orange';
        return (
          <Nav.Item key={item} className='position-relative pe-pointer'>
            <Nav.Link
              eventKey={item}
              className={
                isSelected && hasError ? 'text-danger border-danger' : ''
              }
            >
              {capitalizeString(t('glossary:' + item))}
            </Nav.Link>
            {streamStatus && (
              <StatusIndicator
                className='my-2'
                show={hasError || hasWarning}
                status={status}
              />
            )}
          </Nav.Item>
        );
      })}
      <small className='text-xs ms-auto color-medium text-lowercase d-flex align-items-center'>
        {`${lastestUpdate} ${t('second_ago')}`}
        <RefreshIcon className='ms-2' size={16} />
      </small>
    </Nav>
  );
};

export default LiveDetailsNavs;
