import {
  actionTypes,
  stateTypes,
  FisheyeCameraLens,
  FisheyeCameraLens_TM
} from 'constants/live';
import {
  formatTime,
  timeDifference,
  nsToTime,
  nsToSeconds,
  setNumber,
  checkIsTM
} from 'utils/utils';

import ErrorIcon from 'remixicon-react/ErrorWarningLineIcon';
import InfoIcon from 'remixicon-react/InformationLineIcon';
import SuccessIcon from 'remixicon-react/CheckboxCircleLineIcon';
import WarningIcon from 'remixicon-react/AlertLineIcon';
import AddProfileIcon from 'remixicon-react/FileAddLineIcon';
import AddTranscoderIcon from 'remixicon-react/VideoAddLineIcon';
import ErrorWarningIcon from 'remixicon-react/ErrorWarningLineIcon';
import EditIcon from 'remixicon-react/PencilLineIcon';
import CopyIcon from 'remixicon-react/FileCopy2LineIcon';
import ExportIcon from 'remixicon-react/Upload2LineIcon';
import DeleteIcon from 'remixicon-react/DeleteBin6LineIcon';
import StartIcon from 'remixicon-react/LiveLineIcon';
import RestartIcon from 'remixicon-react/RestartLineIcon';
import StarIcon from 'remixicon-react/StarSLineIcon';
import StopIcon from 'remixicon-react/StopCircleLineIcon';
import SlateIcon from 'remixicon-react/ImageLineIcon';
import FilmLineIcon from 'remixicon-react/FilmLineIcon';

import FailedIcon from 'remixicon-react/CloseCircleFillIcon';
import CompleteIcon from 'remixicon-react/CheckboxCircleFillIcon';
import Loader from 'components/Loader';

/**
 * Live and Settings Action
 */
export const renderIcon = modalName => {
  const className = 'w-100 h-100';
  switch (modalName) {
    case actionTypes.edit:
    case actionTypes.update:
      return <EditIcon className={className} />;
    case actionTypes.duplicate:
      return <CopyIcon className={className} />;
    case actionTypes.export:
      return <ExportIcon className={className} />;
    case actionTypes.delete:
      return <DeleteIcon className={className} />;
    case actionTypes.addProfile:
      return <AddProfileIcon className={className} />;
    case actionTypes.start:
      return <StartIcon className={className} />;
    case actionTypes.getStarted:
      return <AddTranscoderIcon className={className} />;
    case actionTypes.restart:
      return <RestartIcon className={className} />;
    case actionTypes.stop:
      return <StopIcon className={className} />;
    case 'warning':
      return <ErrorWarningIcon className={className} />;
    case 'set_default':
      return <StarIcon className={className} />;
    case 'activate_slate':
      return <SlateIcon className={className} />;
    case actionTypes.live2vod:
      return <FilmLineIcon className={className} />;
    default:
      return null;
  }
};

export const renderLive2VODStatusIcon = ({ stack_uuid, error }) => {
  const isComplete = !Boolean(stack_uuid);
  return isComplete ? (
    <span className={`${error ? 'text-danger' : 'color-primary'} mb-auto`}>
      {error ? <FailedIcon /> : <CompleteIcon />}
    </span>
  ) : (
    <div style={{ width: 24 }}>
      <Loader className='d-inline h-auto w-auto' size='sm' />
    </div>
  );
};

export const renderButtonText = (modalName, requireRestart) => {
  switch (modalName) {
    case actionTypes.start:
      return 'launch';
    case actionTypes.update:
      return requireRestart ? 'restart' : 'update';
    default:
      return null;
  }
};

export const setModalKey = (eventKey, subtitle) =>
  subtitle ? eventKey + subtitle : eventKey;

// TODO:
export const showEventName = output => {
  if (!output) return;
  const key = `$\{EVENT}`;
  const data = JSON.stringify(output);
  return data.includes(key);
};

export const getConfigType = ({ type, groups }) => {
  const isTM = checkIsTM(groups);
  switch (type) {
    default:
    case 'clearvr-pre-meshbox':
      return isTM ? 'Legacy' : 'ClearVR';
    case 'clearvr':
      return isTM ? 'Meshbox' : 'ClearVR';
    case 'clearvr-and-hls':
      return isTM ? 'Meshbox & HLS' : 'ClearVR & HLS';
    case 'hls':
      return 'HLS';
  }
};

export const isPreMeshbox = type => !type || type === 'clearvr-pre-meshbox';

export const setNewAccessUrl = (access_url, eventName) =>
  access_url?.replace(/\${EVENT}/i, eventName);

export const getActionableLiveConfigs = activeTranscoders => {
  const actionableStackIds = activeTranscoders
    .filter(({ state }) => state !== stopping)
    .map(({ stack_uuid }) => stack_uuid);
  const actionableCount = actionableStackIds.length;
  return { actionableStackIds, actionableCount };
};

export const getMatchedData = (data, searchValue) => {
  const matchedData = data?.filter(({ name }) =>
    name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const matchedCount = matchedData.length;
  return { matchedData, matchedCount };
};

export const checkSelectedLiveConfig = ({ currentId, selectedId }) => {
  if (!selectedId) return;
  return currentId === selectedId || selectedId.includes(currentId);
};

const checkFisheyeChanges = (initialValues, values) => {
  const initialFisheye = initialValues?.camera_lens_enum;
  const newFisheye = values?.camera_lens_enum;
  return initialFisheye
    ? JSON.stringify(initialFisheye) !== JSON.stringify(newFisheye)
    : false;
};

export const checkRestart = ({ type, dynamic_config }, values) => {
  const initialConfig = dynamic_config?.config_parameters;
  const newConfig = values.dynamic_config?.config_parameters;
  const isConfigChange =
    JSON.stringify(initialConfig) !== JSON.stringify(newConfig);

  // type === 'clearvr-pre-meshbox' (clearvr-encoding-platform-old-version)
  // type === 'clearvr' (clearvr-encoding-platform-new-version with meshbox)
  // type === 'clearvr-and-hls'
  const initialFisheye = dynamic_config?.runtime_parameters?.fish_eye_params;
  const newFisheye = values.dynamic_config?.runtime_parameters?.fish_eye_params;
  const isFisheyeChange =
    type === 'clearvr' || type === 'clearvr-and-hls'
      ? checkFisheyeChanges(initialFisheye, newFisheye)
      : false;

  return isConfigChange || isFisheyeChange;
};

export const checkFisheye = projection_type => {
  return projection_type?.startsWith('fish-eye');
};

export const setFishEyeValues = (projection_type, fish_eye_params) => {
  const isFisheye = checkFisheye(projection_type);
  if (!isFisheye) return null;
  return {
    fish_eye_params: {
      camera_lens_enum: fish_eye_params?.camera_lens_enum || ''
    }
  };
};

/**
 * Live State
 */

const { preparing, deploying, reconfiguring, stopping, running, error } =
  stateTypes;

export const checkIsActive = state => {
  switch (state) {
    case reconfiguring:
    case running:
    case error:
      return true;
    default:
      return false;
  }
};

export const setAlertProps = state => {
  switch (state) {
    case 'restart':
      return { type: 'warning', text: 'alert_warning' };
    case preparing:
    case deploying:
      return { type: 'info', text: 'alert_preparing' };
    // case reconfiguring:
    case stopping:
      return { type: 'info', text: 'alert_stopping' };
    case error:
      return { type: 'danger', text: 'alert_error' };
    default:
      break;
  }
};

export const getTrafficLightBg = status => {
  switch (status) {
    case 'green':
    default:
      return 'success';
    case 'orange':
      return 'warning';
    case 'red':
      return 'danger';
  }
};

export const renderAlertIcon = variant => {
  const size = 24;
  switch (variant) {
    case 'danger':
      return <ErrorIcon size={size} />;
    case 'info':
      return <InfoIcon size={size} />;
    case 'success':
      return <SuccessIcon size={size} />;
    case 'warning':
      return <WarningIcon size={size} />;
    default:
      return null;
  }
};

/**
 * Live Details
 */

const parseJitter = jitter => [
  {
    title: 'Average jitter',
    subtitle: '(SS.SSS)',
    data: [
      { Current: nsToSeconds(jitter?.avg_segment_interval_lastN) || 0 },
      { Lifetime: nsToSeconds(jitter?.avg_segment_internal_lifetime) || 0 }
    ]
  },
  {
    title: 'Max jitter',
    subtitle: '(SS.SSS)',
    data: [
      { Current: nsToSeconds(jitter?.longest_segment_interval_lastN) || 0 },
      {
        Lifetime: nsToSeconds(jitter?.longest_segment_interval_lifetime) || 0
      }
    ]
  }
];

const parseSRT = srt_stats => [
  {
    title: 'connection',
    data: [
      { 'Measured RTT (ms)': srt_stats?.ms_rtt },
      { 'Available bandwidth (Mbps)': srt_stats?.mbps_bandwidth }
    ]
  },
  {
    title: 'SRT info',
    data: [
      { 'Packets lost': srt_stats?.pkt_rcv_filter_loss_total },
      { 'Retransmitted packets': srt_stats?.pkt_rcv_retrans },
      { NACK: srt_stats?.pkt_sent_nak_total }
    ]
  }
];

const parseErrors = (text, errors) => {
  if (errors === undefined) return [];
  return [{ [text]: errors }];
};

export const parseIngestStatus = ({ ingest_status, lastestSegment }) =>
  ingest_status?.map(status => {
    // TODO: status?.rtmp_ingest_details
    const ingestInfo = status?.hls_ingest_details
      ? parseJitter(status.hls_ingest_details)
      : status?.srt_ingest_details
      ? parseSRT(status?.srt_ingest_details?.srt_dynamic_stats)
      : [];

    const ingestDetails =
      status?.hls_ingest_details ||
      status?.srt_ingest_details ||
      status?.rtmp_ingest_details;

    const ingestErrors = [
      ...parseErrors('PES errors', ingestDetails?.pes_packet_errors),
      ...parseErrors('CC errors', ingestDetails?.cc_errors)
    ];

    return [
      {
        title: 'Activity',
        subtitle: timeDifference(Date.now(), lastestSegment),
        data: [
          { 'Last segment': `${setNumber(status?.num_downloaded_segments)}` }
        ]
      },
      {
        title: 'Corruption',
        data: [{ Discontinuities: status?.discontinuities }, ...ingestErrors]
      },
      {
        title: 'Duration',
        subtitle: '(HH:MM:SS)',
        data: [
          { Ingested: nsToTime(status?.ingested_duration) },
          { Expected: nsToTime(status?.expected_ingested_duration) }
        ]
      },
      ...ingestInfo,
      {
        title: 'Frames',
        data: [
          { Dropped: status?.dropped_frames },
          { Duplicated: status?.duplicated_frames }
        ]
      }
    ];
  });

export const setFisheyeOptions = groups =>
  checkIsTM(groups) ? FisheyeCameraLens_TM : FisheyeCameraLens;

export const formateLogs = messages =>
  messages.map(({ m, t }) => `[${formatTime(new Date(t))}] ${m}`);
