import { nanoid } from '@reduxjs/toolkit';
import { ProfileFormFields, defaultRateControl } from 'constants/forms';
import { setFishEyeValues, showEventName, isPreMeshbox } from 'utils/liveUtils';

export const capitalizeString = string =>
  string?.charAt(0).toUpperCase() + string?.slice(1);

export const flattenObject = (obj, parent, res = {}) => {
  for (const key of Object.keys(obj)) {
    const propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] === 'object') flattenObject(obj[key], propName, res);
    else res[propName] = obj[key];
  }
  return res;
};

export const formatProfileWithNewUUID = profileData => ({
  ...profileData,
  uuid: nanoid(),
  set_id: profileData?.set_id || '',
  customer_entrypoint: profileData?.customer_entrypoint || ''
});

export const resetProfile = ({ profiles, defaultProfile }) => {
  const profileCount = profiles?.length;
  if (!profileCount) return null;
  if (defaultProfile) {
    const index = profiles?.findIndex(({ uuid }) => uuid === defaultProfile);
    return index === -1 ? null : profiles[index];
  } else {
    const lastIndex = profileCount - 1;
    return profiles[lastIndex];
  }
};

const defaultBundles = [
  {
    groups: [
      {
        representations: [{ bitrate_in_kbps: 0 }, { bitrate_in_kbps: 0 }]
      },
      {
        representations: [
          { bitrate_in_kbps: 0 },
          { bitrate_in_kbps: 0 },
          { bitrate_in_kbps: 0 }
        ]
      }
    ]
  }
];

export const getPreset = ({ constant, preset }) => {
  switch (constant) {
    case 'crf':
    case 'quality':
      return preset === 'default' || preset === 'custom' ? 'medium' : preset;
    case 'bitrate':
      return preset === 'medium' ? 'default' : preset;
    case 'full-custom':
      return 'custom';
    default:
      return '';
  }
};

export const showCustomBitrate = ({ constant, preset }) => {
  if (preset === 'custom') return true;
  if (constant === 'full-custom') return true;
  return false;
};

export const parseRatePreset = ({
  rate_control_preset,
  rate_control_custom_bitrate_in_kbps: custom_bitrate
}) => {
  if (rate_control_preset === 'custom')
    return { constant: 'full-custom', preset: 'custom', custom_bitrate };
  const [constant, , preset] = rate_control_preset?.split('-');
  const setConstant = constant => {
    switch (constant) {
      case 'crf':
        return 'quality';
      default:
        return constant || defaultRateControl.constant;
    }
  };
  return {
    constant: setConstant(constant),
    preset: getPreset({ constant, preset }),
    custom_bitrate: custom_bitrate || defaultRateControl.custom_bitrate
  };
};

export const removeCustomBitrate = bundle => {
  const parsedBundle = { ...bundle };
  if (!showCustomBitrate(parsedBundle)) delete parsedBundle.custom_bitrate;
  return parsedBundle;
};

export const setRuntimeParams = (dynamic_config, static_config, type) => {
  const runtime_parameters = { ...dynamic_config?.runtime_parameters };
  const { gaussian_sharpness_filter, fish_eye_params, bundles, ...rest } =
    runtime_parameters;
  const parsedBundles = isPreMeshbox(type)
    ? bundles || defaultBundles
    : bundles?.map(bundle => bundle && parseRatePreset(bundle));
  const fishEyeValues = setFishEyeValues(
    static_config?.source?.video?.projection_type,
    fish_eye_params
  );
  return {
    gaussian_sharpness_filter: gaussian_sharpness_filter || 0,
    ...fishEyeValues,
    bundles: parsedBundles,
    ...rest
  };
};

export const initializeValues = modalContents =>
  modalContents.map(({ name, static_config, type, ...values }) => {
    const dynamic_config = values?.dynamic_config;
    const runtime_parameters = setRuntimeParams(
      dynamic_config,
      static_config,
      type
    );
    const output = static_config?.output || static_config?.egress;
    const showEvent = showEventName(output);
    const config_parameters = {
      ...dynamic_config.config_parameters,
      event_name: showEvent
        ? dynamic_config.config_parameters?.event_name || ''
        : 'no_event_name'
    };
    return {
      ...values,
      dynamic_config: { config_parameters, runtime_parameters },
      static_config: static_config?.egress
        ? { name, egress: static_config.egress }
        : { name, output }
    };
  });

const setRateControlPreset = ({ constant, preset }) => {
  switch (constant) {
    case 'quality':
      return `crf-control-${preset === 'medium' ? 'default' : preset}`;
    case 'bitrate':
      return `bitrate-control-${preset}`;
    case 'full-custom':
      return preset;
    default:
      return;
  }
};

export const parseRateControl = bundle => {
  const { constant, preset, custom_bitrate, ...rest } = { ...bundle };
  const rate_control_preset = setRateControlPreset({ constant, preset });
  const payload = { rate_control_preset, ...rest };
  if (showCustomBitrate({ constant, preset }) && custom_bitrate)
    payload['rate_control_custom_bitrate_in_kbps'] = custom_bitrate;
  return payload;
};

export const formatValues = initialValues => {
  const values = Array.isArray(initialValues) ? initialValues : [initialValues];
  return values.map(value => {
    const runtime_parameters = value.dynamic_config?.runtime_parameters;
    const gaussian_sharpness_filter =
      runtime_parameters?.gaussian_sharpness_filter || 0;
    const bundles = runtime_parameters?.bundles?.map(bundle =>
      parseRateControl(bundle)
    );
    return {
      config_uuid: value.config_uuid,
      dynamic_config: {
        config_parameters: {
          ...value?.dynamic_config?.config_parameters,
          enable_recording: true
        },
        runtime_parameters: {
          ...runtime_parameters,
          bundles,
          gaussian_sharpness_filter
        }
      }
    };
  });
};

export const setPrimary = theme => {
  switch (theme) {
    case 'dark':
      return 'secondary';

    default:
    case 'light':
      return 'primary';
  }
};

export const sortData = (data, sortColumn) => {
  if (!data) return [];
  return [...data].sort((a, b) => {
    const value_a = a[sortColumn.path]?.toLowerCase() || '-';
    const value_b = b[sortColumn.path]?.toLowerCase() || '-';

    const order = sortColumn.order === 'asc' ? 1 : -1;
    if (value_a > value_b) return 1 * order;
    if (value_a < value_b) return -1 * order;
    return 0;
  });
};

export const sortByObject = data =>
  data.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index
    }),
    {}
  );

export const filterCheckedData = (allData, checkedData) =>
  allData
    .filter(
      allProfile =>
        !checkedData.some(
          checkedProfile => allProfile.uuid === checkedProfile.uuid
        )
    )
    .map(uncheckedProfile =>
      ProfileFormFields.initialKeys.reduce((uncheckedData, name) => {
        uncheckedData[name] = uncheckedProfile[name];
        return uncheckedData;
      }, {})
    );

export const trimmedSpace = string => string.replace(/\s/g, '');

export const getToday = () => {
  const today = new Date();
  const year = today.getUTCFullYear();
  const month = pad(today.getUTCMonth() + 1);
  const day = pad(today.getUTCDate());
  return year + month + day;
};

export const downloadFile = ({ data, fileName, fileType, callback }) => {
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  if (callback) callback();
};

export const populateProfileValues = currentProfile =>
  ProfileFormFields.initialKeys.reduce((formattedProfile, name) => {
    formattedProfile[name] = currentProfile?.[name] || '';
    return formattedProfile;
  }, {});

const pad = (n, z = 2) => ('00' + n).slice(-z);

export function formatTime(localIsoDate) {
  const hh = localIsoDate.getUTCHours();
  const mm = localIsoDate.getUTCMinutes();
  const ss = localIsoDate.getUTCSeconds();
  return pad(hh) + ':' + pad(mm) + ':' + pad(ss);
}

export function formatLocaleTime(localIsoDate) {
  const localeOptions = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  return new Date(localIsoDate).toLocaleTimeString([], localeOptions);
}

export const msToTime = (ms, ns) => {
  if (!ms) return ns ? '00.000' : '00:00:00';

  let milliseconds = ms % 1000;
  ms = (ms - milliseconds) / 1000;
  let seconds = ms % 60;
  ms = (ms - seconds) / 60;
  let minutes = ms % 60;
  let hours = (ms - minutes) / 60;

  return ns
    ? `${pad(seconds)}.${pad(milliseconds, 3)}`
    : `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export const nsToTime = nanoseconds => msToTime(nanoseconds / 1000000);
export const nsToSeconds = nanoseconds => msToTime(nanoseconds / 1000000, 'ns');

export const timeDifference = (current, previous) => {
  if (!previous) return '0 second ago';

  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;
  let elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
};

export const bytesToSize = bytes => {
  if (!bytes) return { size: 0, unit: 'Byte' };
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return {
    size: `${(bytes / 1024 ** i).toFixed(2)}`,
    unit: `${sizes[i]}`
  };
};

export const setBitrates = bitrates =>
  bitrates ? (bitrates / 1000000).toFixed(2) : 0;

export const setNumber = number => number?.toLocaleString('en') || 0;
export const setNode = count => `${count} ${count > 1 ? ' nodes' : ' node'}`;

export const checkIsTM = groups => groups?.includes('tiledmedia');
