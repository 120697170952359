import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setLiveProfiles, setVodProfiles } from 'store/user/userSlice';
import { sortData } from 'utils/utils';
import { getLocationTypeOption } from 'utils/vodUtils';
import { actionTypes } from 'constants/live';
import { VOD_TYPES } from 'constants/vod';
import Table from 'components/Table';
import CheckBox from 'components/Checkbox';
import ActionDropdown from 'components/ActionDropdown';
import Badge from 'components/Badge';

const SettingsTable = ({ eventKey, ...props }) => {
  switch (eventKey) {
    default:
    case 'live':
      return <Live {...props} />;
    case 'vod':
      return <Vod {...props} />;
  }
};

const Live = ({ onSelect }) => {
  const { t } = useTranslation(['common', 'validation']);

  const dispatch = useDispatch();
  const { live_profiles, default_live_uuid } = useSelector(({ user }) => user);
  const [sortColumn, setSortColumn] = useState({ path: 'name', order: 'asc' });

  const handleSort = sortColumn => {
    setSortColumn(sortColumn);
  };

  const totalCount = live_profiles?.length;
  const checked = live_profiles?.filter(({ checked }) => checked);
  const checkedCount = checked?.length;

  const getEventKeys = uuid => [
    { eventKey: 'set_default', disabled: default_live_uuid === uuid },
    { eventKey: actionTypes.edit },
    { eventKey: actionTypes.duplicate },
    { eventKey: actionTypes.export },
    { eventKey: actionTypes.delete, danger: true }
  ];

  const checkboxColumn = totalCount
    ? {
        key: 'checkbox',
        checked: checkedCount === totalCount,
        content: profile => (
          <CheckBox.Box
            checked={profile.checked}
            onClick={() => handleCheck(profile)}
          />
        )
      }
    : { key: 'checkbox' };

  const columns = [
    checkboxColumn,
    { path: 'name', label: `${t('profile')} (${totalCount || 0})` },
    { path: 'set_id', label: t('validation:Set ID') },
    { path: 'customer_entrypoint', label: t('validation:customer_entrypoint') },
    {
      key: 'action',
      content: profile => (
        <ActionDropdown
          eventKeys={getEventKeys(profile.uuid)}
          onSelect={event => onSelect(event, profile)}
          disabled={profile.checked}
        />
      )
    }
  ];

  const handleCheck = profile => {
    let profiles = [...live_profiles];
    if (profile.key === 'checkbox') {
      profiles = profiles.map(item => ({ ...item, checked: !profile.checked }));
    } else {
      const index = profiles?.findIndex(({ uuid }) => uuid === profile.uuid);
      profiles[index] = { ...profiles[index] };
      profiles[index].checked = !profiles[index].checked;
    }
    dispatch(setLiveProfiles(profiles));
  };

  return (
    <Table
      className='dp-01'
      data={sortData(live_profiles, sortColumn)}
      columns={columns}
      sortColumn={sortColumn}
      onSort={handleSort}
    />
  );
};

const Vod = ({ onSelect }) => {
  const { t } = useTranslation(['glossary', 'validation']);

  const dispatch = useDispatch();
  const { hash } = useLocation();
  const { default_vod_uuid, vod_profiles } = useSelector(({ user }) => user);
  const [sortEncoderColumn, setSortEncoderColumn] = useState({
    path: 'name',
    order: 'asc'
  });
  const [sortSourceColumn, setSortSourceColumn] = useState({
    path: 'type',
    order: 'asc'
  });
  const [sortDestinationColumn, setSortDestinationColumn] = useState({
    path: 'type',
    order: 'asc'
  });
  const handleSortEncoders = sortColumn => setSortEncoderColumn(sortColumn);
  const handleSortSources = sortColumn => setSortSourceColumn(sortColumn);
  const handleSortDestinations = sortColumn =>
    setSortDestinationColumn(sortColumn);

  const vodTableConfigs = {
    encoders: { sortColumn: sortEncoderColumn, onSort: handleSortEncoders },
    sources: { sortColumn: sortSourceColumn, onSort: handleSortSources },
    destinations: {
      sortColumn: sortDestinationColumn,
      onSort: handleSortDestinations
    }
  };

  const {
    edit,
    duplicate,
    export: exportProfile,
    delete: deleteProfiles
  } = actionTypes;

  const getEventKeys = (key, uuid) => {
    let eventKeys = [
      { eventKey: edit },
      { eventKey: duplicate },
      { eventKey: exportProfile },
      { eventKey: deleteProfiles, danger: true }
    ];
    if (key === 'encoder')
      eventKeys.unshift({
        eventKey: 'set_default',
        disabled: default_vod_uuid === uuid
      });
    return eventKeys;
  };

  const getNameColumn = (key, count) => ({
    path: 'name',
    label: `${t(`glossary:${key}`)} (${count || 0})`
  });

  const getCheckboxColumn = (key, count) => {
    if (!count) return { key: 'checkbox' };
    const checkedProfiles = vod_profiles[key]?.filter(({ checked }) => checked);
    return {
      key: 'checkbox',
      checked: checkedProfiles?.length === count,
      content: profile => (
        <CheckBox.Box
          checked={profile.checked}
          onClick={() => handleCheck(key, profile)}
        />
      )
    };
  };

  const populateVodColumns = (key, columns) => {
    const newCoulmns = [...columns];
    const count = vod_profiles[key]?.length;
    newCoulmns?.unshift(
      getCheckboxColumn(key, count),
      getNameColumn(key, count)
    );
    newCoulmns?.push({
      key: 'action',
      content: profile => (
        <ActionDropdown
          eventKeys={getEventKeys(key.slice(0, -1), profile.uuid)}
          onSelect={event => onSelect(event, profile, key)}
          disabled={profile.checked}
        />
      )
    });
    return newCoulmns;
  };

  const getColumns = key => {
    let columns;
    if (key === 'encoders')
      columns = [
        {
          path: 'customer_entrypoint',
          label: t('validation:customer_entrypoint')
        },
        { path: ['notification', 'type'], label: t('validation:notification') }
      ];
    else
      columns = [
        {
          path: 'type',
          label: t('validation:type'),
          content: profile => (
            <Badge.Fill type={key}>
              {getLocationTypeOption(profile.type)}
            </Badge.Fill>
          )
        }
      ];
    if (key === 'destinations')
      columns.push({
        path: 'base_url',
        label: t('validation:Access base URL')
      });
    return populateVodColumns(key, columns);
  };

  const handleCheck = (key, profile) => {
    const profiles = { ...vod_profiles };
    profiles[key] = [...profiles[key]];
    if (profile.key === 'checkbox') {
      profiles[key] = profiles[key].map(item => ({
        ...item,
        checked: !profile.checked
      }));
    } else {
      const index = profiles[key]?.findIndex(
        ({ uuid }) => uuid === profile.uuid
      );
      profiles[key][index] = { ...profiles[key][index] };
      profiles[key][index].checked = !profiles[key][index].checked;
    }
    dispatch(setVodProfiles(profiles));
  };

  return (
    <section>
      {VOD_TYPES.map(key => {
        if (hash && key !== hash.slice(1)) return null;

        const configs = vodTableConfigs[key];
        const profiles = vod_profiles[key];
        const props = {
          key,
          data: profiles?.length ? sortData(profiles, configs.sortColumn) : [],
          columns: getColumns(key),
          ...configs
        };
        return <Table className='dp-01' {...props} />;
      })}
    </section>
  );
};

export default SettingsTable;
