import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'store/user/userThunk';

import Button from 'react-bootstrap/Button';
import ConfigMethodOption from 'components/ConfigMethodOption';
import Checkbox from 'components/Checkbox';
import ImportIcon from 'remixicon-react/FileDownloadLineIcon';
import EditIcon from 'remixicon-react/Edit2LineIcon';

const StartMethod = ({ onGoNext, onSubmit }) => {
  const dispatch = useDispatch();
  const hideStarted = useSelector(
    ({ user }) => user?.settings?.hide_getting_started
  );

  const { t } = useTranslation();
  const [currentMethodIndex, setCurrentMethodIndex] = useState(null);
  const [file, setFile] = useState(null);
  let fileReader;

  const configureMethods = [
    {
      icon: <ImportIcon size={40} />,
      content: t('config_method_1', { returnObjects: true }),
      openFile: true
    },
    {
      icon: <EditIcon size={40} />,
      content: t('config_method_2', { returnObjects: true })
    }
  ];

  const handleFileRead = e => {
    const content = fileReader.result;
    const name = fileReader.fileName;
    setFile({ name, data: JSON.parse(content) });
  };

  const handleChange = ({ target: input }) => {
    const file = input.files[0];
    fileReader = new FileReader();
    fileReader.fileName = file.name;
    fileReader.onloadend = handleFileRead;
    if (file) fileReader.readAsText(file);
    else setFile(null);
  };

  const handleHideStarted = () => {
    dispatch(updateSettings({ hide_getting_started: !hideStarted }));
  };

  const handleContinue = index => {
    setCurrentMethodIndex(index);
    if (index) onGoNext();
  };

  return (
    <>
      <div className='px-0 d-grid gap-4 mb-4'>
        {configureMethods.map((method, index) => (
          <ConfigMethodOption
            key={index}
            file={file}
            selected={currentMethodIndex === index}
            onChange={handleChange}
            onClick={() => handleContinue(index)}
            {...method}
          />
        ))}
      </div>

      <div className='d-flex justify-content-between align-items-center px-0 my-5'>
        <Checkbox.Text
          id='dontAskAgain'
          checked={hideStarted}
          onChange={handleHideStarted}
        >
          {t('dont_ask_again')}
        </Checkbox.Text>
        <Button
          variant='primary'
          className={`transition border-0 ${
            currentMethodIndex === 0 && file ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={() => onSubmit(file?.data)}
        >
          {t('import')}
        </Button>
      </div>
    </>
  );
};

export default StartMethod;
