import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'config/i18next';

import { importLiveProfile, importVodProfile } from 'store/user/userThunk';
import { setProfile } from 'store/live/liveSlice';

import Button from 'react-bootstrap/Button';
import Step from 'components/Step';
import ProfileForm from 'components/ProfileForm';
import StartMethod from 'components/StartMethod';
import Loader from 'components/Loader';

const StartPage = ({ history }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation(['common', 'glossary']);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);

  const steps = [
    { index: 1, text: t('configuration_method') },
    { index: 2, text: t('profile_setup') }
  ];

  const handleGoBack = () => {
    setCurrentStep(preState => preState - 1);
  };

  const handleGoNext = () => {
    setCurrentStep(preState => preState + 1);
  };

  const handleRedirect = () => {
    history.push(`/${i18n.language}/live`);
  };

  const handleSubmit = async file => {
    const { live_profiles, vod_profiles } = file;
    if (live_profiles) {
      const response = await dispatch(importLiveProfile(live_profiles));
      if (response['meta']['requestStatus'] === 'fulfilled') {
        dispatch(setProfile(live_profiles[0]));
        handleRedirect();
      }
    }
    if (vod_profiles) {
      const response = await dispatch(importVodProfile(vod_profiles));
      if (response['meta']['requestStatus'] === 'fulfilled') {
        handleRedirect();
      }
    }
  };

  return (
    <main className='row flex-column flex-fill d-flex align-items-start col-10 col-xl-8 col-xxxl-6 offset-1'>
      {user.loading && <Loader fullscreen />}
      <div className='d-flex flex-column flex-xl-row align-items-start underline px-0'>
        {steps?.map(step => (
          <Step key={step.index} step={step} currentStep={currentStep} />
        ))}
      </div>

      <div className='d-flex justify-content-between align-items-center px-0 my-5'>
        <h2 className='color-high m-0 w-auto'>
          {currentStep === 1 ? t('getting_started') : 'Create Live Profile'}
        </h2>
        {currentStep === 1 && (
          <Button
            variant='outline-primary'
            className='bg-transparent border-0 btn-empty'
            onClick={handleRedirect}
          >
            {t('skip')}
          </Button>
        )}
      </div>

      {currentStep === 1 ? (
        <StartMethod onGoNext={handleGoNext} onSubmit={handleSubmit} />
      ) : (
        <ProfileForm mode='start' onCancel={handleGoBack} onConfirm={handleRedirect} />
      )}
    </main>
  );
};

export default withRouter(StartPage);
