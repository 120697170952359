import * as Yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Formik, Form } from 'formik';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';

import { AddActiveRecord, clearHistoryRecords } from 'store/live2vod/live2vodSlice';
import { scheduleLive2VOD, listLive2VODRecords } from 'store/live2vod/live2vodThunk';
import { renderLive2VODStatusIcon } from 'utils/liveUtils';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import EmptyButton from 'components/EmptyButton';
import Loader from 'components/Loader';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'components/Table';
import TextField from 'components/TextField';

const FORMAT = 'YYYY-MM-DDTHH:mm';
const TYPES = ['local', 'unix'];

const Live2VodForm = ({ onCancel }) => {
  dayjs.extend(customParseFormat);
  dayjs.extend(isSameOrAfter);

  const dispatch = useDispatch();
  const content = useSelector(({ modal }) => modal.content);
  const { active, history } = useSelector(({ live2vod }) => live2vod);

  const focusref = useRef(null);
  const [selectedType, setSelectedType] = useState(TYPES[0]);

  const initialValues = {
    name: content.name,
    stack_uuid: content?.last_stack_uuid,
    start_time: dayjs(content?.state_since).format(FORMAT),
    end_time: dayjs().format(FORMAT)
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required_field'),
    start_time:
      selectedType === 'local'
        ? Yup.string()
        : Yup.number().typeError('must_be_number').integer(),
    end_time:
      selectedType === 'local'
        ? Yup.string().test(
            'is-greater',
            'End time should be greater',
            (value, { parent: { start_time } }) => {
              return dayjs(value, FORMAT).isSameOrAfter(dayjs(start_time, FORMAT));
            }
          )
        : Yup.number()
            .typeError('must_be_number')
            .integer()
            .test(
              'is-greater',
              'End time should be greater',
              (value, { parent: { start_time } }) => {
                return value >= start_time;
              }
            )
  });

  const columns = [
    {
      path: 'status',
      content: props => {
        const { error } = { ...props };
        if (!error) return renderLive2VODStatusIcon(props);
        return (
          <OverlayTrigger
            overlay={
              <Popover>
                <Popover.Header as='h3'>
                  {dayjs(error?.t).format('YYYY-MM-DD HH:mm')}
                </Popover.Header>
                <Popover.Body className='text-danger'>{error?.m}</Popover.Body>
              </Popover>
            }
          >
            {renderLive2VODStatusIcon(props)}
          </OverlayTrigger>
        );
      }
    },
    {
      path: 'name',
      label: 'Name',
      content: ({ name }) => name.substr(0, name.lastIndexOf('-'))
    },
    {
      path: 'hls_manifest_filename',
      label: 'Filename',
      content: ({ hls_manifest_filename, clearvr_manifest_filename }) => {
        if (hls_manifest_filename && clearvr_manifest_filename) {
          return hls_manifest_filename + ', ' + clearvr_manifest_filename;
        }
        return hls_manifest_filename || clearvr_manifest_filename;
      }
    },
    {
      path: 'access_urls',
      label: 'Access URL',
      content: ({ access_urls }) => (
        <ul className='list-unstyled mb-0'>
          {access_urls?.map(url => (
            <li key={url}>{url}</li>
          ))}
        </ul>
      )
    }
  ];

  const convertTime = time => {
    if (selectedType === 'local') return dayjs(time, FORMAT).unix();
    return dayjs.unix(time).format(FORMAT);
  };

  const timeToString = time => {
    if (selectedType === 'local') return dayjs(time, FORMAT).toISOString();
    return dayjs.unix(time).toISOString();
  };

  const handleScheduleLive2Vod = async ({ start_time, end_time, ...rest }) => {
    const payload = {
      start_time: timeToString(start_time),
      end_time: timeToString(end_time),
      ...rest
    };
    payload.name += `-${dayjs().unix()}`;

    const response = await dispatch(scheduleLive2VOD(payload));
    const { requestStatus: status } = { ...response.meta };
    if (status === 'fulfilled') dispatch(AddActiveRecord(payload));
    if (status === 'fulfilled' || status === 'rejected') onCancel();
  };

  useEffect(() => {
    focusref.current.focus();
    const { last_stack_uuid: stack_uuid } = { ...content };
    dispatch(listLive2VODRecords({ stack_uuid }));
    return () => dispatch(clearHistoryRecords());
  }, []); // eslint-disable-line

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleScheduleLive2Vod}
    >
      {({ values, setFieldValue, setFieldTouched }) => {
        const timeProps = {
          className: 'col-12 col-lg-6 col-xxl-4',
          type: selectedType === 'local' ? 'datetime-local' : 'number'
        };

        const handleClick = type => {
          setSelectedType(type);
          const start_value = convertTime(values.start_time);
          setFieldValue('start_time', start_value);
          const end_value = convertTime(values.end_time);
          setFieldValue('end_time', end_value);
          setFieldTouched('end_time', false);
        };
        return (
          <Form className='d-flex flex-column justify-content-between w-100'>
            {active.loading ? (
              <Loader />
            ) : (
              <section className='px-1'>
                <div className='row'>
                  <TextField
                    className='col-12 col-xxl-8 me-xxl-5'
                    name='name'
                    label='Manifest name'
                    focusref={focusref}
                    validation
                  />
                  <FormatField
                    className='col-12 col-lg-6 me-lg-5 mb-4 col-xxl-4 me-xxl-0'
                    selectedType={selectedType}
                    onClick={handleClick}
                  />
                  <TextField name='start_time' label='Start time' {...timeProps} />
                  <TextField name='end_time' label='End time' validation {...timeProps} />
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <h5 className='text-md color-high m-0 py-3 underline'>
                      VoD Manifest History
                    </h5>
                    {history.loading ? (
                      <Loader className='h-auto my-4' />
                    ) : history.records?.length ? (
                      <Table
                        className='video-table live2vod-table text-sm'
                        data={history.records}
                        columns={columns}
                        hover={false}
                        size='sm'
                      />
                    ) : (
                      <h6 className='fw-normal color-medium py-2'>No data</h6>
                    )}
                  </div>
                </div>
              </section>
            )}
            <Modal.Footer>
              <EmptyButton onClick={onCancel}>
                <Trans i18nKey='cancel'>'cancel'</Trans>
              </EmptyButton>
              <Button type='submit' variant='primary' className='border-0 ms-4'>
                <Trans i18nKey='confirm'>'confirm'</Trans>
              </Button>
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
};

const FormatField = ({ selectedType, onClick, className = 'col-auto' }) => (
  <div className={className}>
    <div className='form-label color-medium'>Time format</div>
    <ButtonGroup className='w-100' aria-label='time format'>
      {TYPES.map(type => (
        <Button
          key={type}
          variant={type === selectedType ? 'primary' : 'outline-primary'}
          className={`text-capitalize shadow-none ${
            type === selectedType ? 'pe-none' : ''
          }`}
          onClick={() => onClick(type)}
        >
          {type} time
        </Button>
      ))}
    </ButtonGroup>
  </div>
);

export default Live2VodForm;
