import { nanoid } from '@reduxjs/toolkit';

export const FisheyeCameraLens = [
  'choose_camera_lens',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@8mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@8.5mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@9mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@9.5mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@10mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@10.5mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@11mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@11.5mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@12mm',
  'blackmagic-ursa-mini_canon8-15mmF4.0_@12.5mm',
  'blackmagic-ursa-12k_canon8-15mmF4.0_@8mm_8K_16:9',
  'zcam-k1-pro_izugar-mkx22mft-3.25mm_@3.25mm',
  'zcam-k2-pro_izugar-mkx200-3.8mm_@3.8mm',
  'red-komodo-6K-canon8_12mm_@8mm',
  'red-v-raptor-8K-canon8_15mm_@8mm',
  'red-v-raptor-8K-canon8_15mm_@10mm',
  'red-v-raptor-8K-canon8_15mm_@13mm'
];

export const FisheyeCameraLens_TM = [
  ...FisheyeCameraLens,
  'test-polynomial-fisheye-camera'
];

export const NoFisheyeProjectionTypes = [
  'mono type',
  '360-erp-mono',
  '180-erp-mono',
  '3x2-cubemap-mono',
  '3x2-mpeg-cubemap-mono',
  'stereo type',
  '360-erp-stereo-top-bottom',
  '180-erp-stereo-left-right',
  // '180-erp-stereo-top-bottom',
  '3x2-cubemap-stereo-top-bottom',
  '3x2-mpeg-cubemap-stereo-top-bottom'
];

export const FisheyeProjectionTypes = ['fish-eye-mono', 'fish-eye-stereo-left-right'];

export const rate_control_preset = [
  'bitrate-control-low',
  'bitrate-control-default',
  'bitrate-control-high',
  'bitrate-control-custom',
  'crf-control-low',
  'crf-control-medium',
  'crf-control-high',
  'crf-control-custom',
  'custom'
];

export const actionTypes = {
  edit: 'edit',
  duplicate: 'duplicate',
  update: 'update',
  import: 'import',
  export: 'export',
  delete: 'delete',
  getStarted: 'getStarted',
  addProfile: 'addProfile',
  start: 'start',
  startAll: 'startAll',
  restart: 'restart',
  addChart: 'addChart',
  stop: 'stop',
  live2vod: 'live2vod',
  checkDetails: 'checkDetails',
  checkConfig: 'checkConfig',
  checkRunningConfig: 'checkRunningConfig',
  checkRunningLogs: 'checkLogs',
  checkIngestDetails: 'checkIngestDetails'
};

export const stateTypes = {
  preparing: 'preparing',
  deploying: 'deploying',
  deployed: 'deployed',
  reconfiguring: 'reconfiguring',
  stopping: 'stopping',
  running: 'running',
  slate: 'slate',
  error: 'error'
};

export const liveState = [
  { key: nanoid(), state: stateTypes.preparing },
  { key: nanoid(), state: stateTypes.deploying },
  { key: nanoid(), state: stateTypes.deployed },
  { key: nanoid(), state: stateTypes.running },
  { key: nanoid(), state: stateTypes.reconfiguring },
  { key: nanoid(), state: stateTypes.stopping },
  { key: nanoid(), state: stateTypes.error }
];

export const showDetailsStates = [
  stateTypes.reconfiguring,
  stateTypes.running,
  stateTypes.error
];

export const streamStages = [
  { key: nanoid(), stage: 'ingest' },
  { key: nanoid(), stage: 'encode' },
  { key: nanoid(), stage: 'egress' }
];

// grafana

export const URL = 'https://grafana.monitoring.tiled.media/d-solo/DsyrG7-nz/cockpit';
export const URL_CN =
  'https://grafana.monitoring.tiled-media.cn/d-solo/nKnwVEH7k/cockpit';

export const timeRanges = ['now-15m', 'now-30m', 'now-1h', 'now-3h', 'max'];

export const overviewGraphs = [
  {
    panelId: 32,
    title: 'ingestStatusOverview',
    titleText: 'Ingest Status Overview',
    className: 'col-8 pe-0 mt-0'
  },
  {
    panelId: 33,
    maintext: 'Current status',
    containerclass: 'col-4 px-0 pt-2 mt-0',
    className: 'py-3',
    title: 'currentIngestStatus',
    titleText: 'Current Ingest Status'
  },
  {
    panelId: 24,
    title: 'inputOutput',
    titleText: 'FPS (Input/ Output)',
    height: 196 + 72
  },
  {
    panelId: 25,
    title: 'transcoderLatency',
    titleText: 'Platform Latency',
    height: 196 + 72
  }
];

export const overviewIngestGraphs = [
  {
    panelId: 29,
    title: 'bandwidthOverview',
    titleText: 'All Ingest Bandwidths',
    height: 196 + 72
  }
];

export const ingestGraphs = [
  {
    panelId: 26,
    title: 'ingestInput',
    titleText: 'FPS (Input)',
    height: 232
  },
  {
    panelId: 27,
    title: 'ingestBandwidth',
    titleText: 'Bandwidth',
    height: 232
  }
];

export const encodeGraphs = [
  {
    panelId: 7,
    title: 'jobQueues',
    titleText: 'Job Queues'
  },
  {
    panelId: 9,
    title: 'completedJobs',
    titleText: 'Completed Jobs',
    height: 196 + 38
  },
  { panelId: 19, title: 'encodeJobDuration', titleText: 'Encode Job Duration' },
  {
    panelId: 20,
    title: 'projectionJobDuration',
    titleText: 'Projection Job Duration'
  }
];

export const egressGraphs = [
  {
    panelId: 28,
    title: 'egressBandwidth',
    titleText: 'Bandwidth',
    height: 232
  },
  {
    panelId: 30,
    title: 'uploadQueue',
    titleText: 'Upload Queue',
    height: 232
  }
];

export const allGraphs = {
  overview: [...overviewGraphs, ...overviewIngestGraphs],
  ingest: ingestGraphs,
  encode: encodeGraphs,
  egress: egressGraphs
};

export const navLists = ['overview', 'ingest', 'encode', 'egress'];
export const hlsNavLists = navLists.filter(list => list !== 'encode');
