import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setChartConfigs } from 'store/live/liveSlice';
import { openModal, closeModal, clearModalContent } from 'store/modal/modalSlice';

import { actionTypes } from 'constants/live';
import { setModalKey } from 'utils/liveUtils';
import LiveActiveSection from 'components/LiveActiveSection';
import LiveInactiveSection from 'components/LiveInactiveSection';
import LiveGetStartedDialog from 'components/LiveGetStartedDialog';
import LiveFormDialog from 'components/LiveFormDialog';
import LiveChartSection from 'components/LiveChartSection';
import LiveConfigDialog from 'components/LiveConfigDialog';
import LiveDetailsDialog from 'components/LiveDetailsDialog';
import Live2VODRecordList from 'components/Live2VODRecordList';
import CancelModal from 'components/CancelModal';
import WithLoader from 'components/WithLoader';

const Live = ({ activeTranscoders, inactiveTranscoders }) => {
  const [selectedActiveIds, setSelectedActiveIds] = useState([]);
  const [selectedInactiveIds, setSelectedInactiveIds] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);

  const {
    getStarted,
    start,
    startAll,
    update,
    addChart,
    live2vod,
    checkDetails,
    checkConfig,
    checkRunningLogs
  } = actionTypes;

  const dispatch = useDispatch();
  const profileConfig = useSelector(({ live }) => live.profile_config);
  const { showRecordList } = useSelector(({ live2vod }) => live2vod);
  const { name: modalName, content: modalContent } = useSelector(({ modal }) => modal);

  const handleIsDropdownOpen = isOpen => {
    setIsDropdownOpen(isOpen);
  };

  const selectActiveIds = ids => {
    setSelectedActiveIds(ids);
    setSelectedInactiveIds([]);
  };

  const selectInactiveIds = ids => {
    setSelectedActiveIds([]);
    setSelectedInactiveIds(ids);
  };

  const removeInactiveId = uuid => {
    const filteredUUIDs = selectedInactiveIds?.filter(inactiveId => inactiveId !== uuid);
    setSelectedInactiveIds(filteredUUIDs);
  };

  const handleCancel = () => {
    selectActiveIds([]);
    clearModal();
  };

  const handleCancelInactive = () => {
    selectInactiveIds([]);
    clearModal();
  };

  const clearModal = () => {
    dispatch(closeModal());
    dispatch(clearModalContent());
  };

  const handleChange = input => {
    setSearchValue(input);
  };

  useEffect(() => {
    if (modalName && modalName !== getStarted && modalName !== checkDetails) {
      clearModal();
    }
  }, [profileConfig?.uuid]); // eslint-disable-line

  useEffect(() => {
    if (activeTranscoders.length) {
      if (modalName === getStarted) clearModal();
    } else {
      if (!modalName) dispatch(openModal(getStarted));
    }
  }, [activeTranscoders.length, modalName]); // eslint-disable-line

  useEffect(() => {
    if (!activeTranscoders.length) return;
    const configs = activeTranscoders.map(
      ({ stack_uuid, name, state_since, static_config }) => {
        const { video, multi_video } = { ...static_config?.source };
        const inputcount = video ? 1 : multi_video?.length;
        return { stack_uuid, name, state_since, inputcount };
      }
    );
    dispatch(setChartConfigs(configs));
  }, [activeTranscoders.length]); // eslint-disable-line

  return (
    <main className='main-container d-flex flex-fill d-grid gap-4'>
      <CancelModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={() => {
          handleCancel();
          setShowModal(false);
        }}
      />
      {showRecordList && <Live2VODRecordList />}
      <div
        className={`${isDropdownOpen ? 'live-container' : 'live-container-scroll'} col-4`}
      >
        <section className='my-4'>
          <LiveActiveSection
            className='mb-3'
            data={activeTranscoders}
            selectedActiveIds={selectedActiveIds}
            selectActiveIds={selectActiveIds}
            onDropdownOpen={handleIsDropdownOpen}
            onCancel={handleCancel}
          />
          <LiveInactiveSection
            data={inactiveTranscoders}
            selectedInactiveIds={selectedInactiveIds}
            selectInactiveIds={selectInactiveIds}
            searchValue={searchValue}
            onChange={handleChange}
            onCancel={handleCancelInactive}
          />
        </section>
      </div>
      <div
        className={`col position-relative ${
          modalName ? 'live-container' : 'live-container-scroll'
        }`}
      >
        {activeTranscoders.length > 0 && (
          <LiveChartSection
            show={!modalName || modalName === addChart}
            activeTranscoders={activeTranscoders}
            onCancel={handleCancel}
          />
        )}
        {modalName === getStarted && <LiveGetStartedDialog className='mt-4' />}
        {[startAll, start, update, live2vod].map(eventKey => (
          <LiveFormDialog
            key={setModalKey(eventKey, modalContent?.config_uuid)}
            eventKey={eventKey}
            show={modalName === eventKey} // TODO: test
            removeInactiveId={removeInactiveId}
            onCancel={() => {
              if (eventKey === live2vod) handleCancel();
              else setShowModal(true);
            }}
          />
        ))}
        {modalName === checkConfig && (
          <LiveConfigDialog
            key={checkConfig + modalContent?.config_uuid}
            eventKey={checkConfig}
            onCancel={handleCancel}
          />
        )}
        {modalName === checkRunningLogs && (
          <LiveConfigDialog eventKey={checkRunningLogs} onCancel={handleCancel} />
        )}
        {modalName === checkDetails && (
          <LiveDetailsDialog
            selectedActiveId={modalContent?.stack_uuid}
            onCancel={handleCancel}
          />
        )}
      </div>
    </main>
  );
};

export default WithLoader(Live);
