import AscIcon from 'remixicon-react/ArrowUpSLineIcon';

const TableHeader = ({ data, columns, sortColumn, onSort, className = '' }) => {
  const showSortIcon = data?.length > 0 && onSort;

  const renderCell = column => {
    if (column.content && column.key === 'checkbox') return column.content(column);
    return column.label;
  };

  const raiseSort = path => {
    const newColumn = { ...sortColumn };
    if (newColumn.path === path)
      newColumn.order = newColumn.order === 'asc' ? 'desc' : 'asc';
    else {
      newColumn.path = path;
      newColumn.order = 'asc';
    }
    onSort(newColumn);
  };

  const renderSortIcon = column => {
    if (column.key) return;

    const setStyles = (column, sortColumn) => {
      if (column?.path !== sortColumn?.path) return 'd-none';
      if (sortColumn?.order === 'asc') return 'asc-button';
      return 'desc-button';
    };
    return (
      <AscIcon
        className={`fill-primary sort-transition 
          ${setStyles(column, sortColumn)}
        `}
      />
    );
  };

  return (
    <thead className={className}>
      <tr className='color-high align-middle'>
        {columns.map(column => (
          <th
            key={column.path || column.key}
            className={`${showSortIcon ? 'pe-pointer' : 'pe-none'} user-select-none ${
              !column.key
                ? ''
                : column.key === 'btn-sm'
                ? 'w-auto'
                : 'icon-column-width text-center'
            } ${column?.thClasses || ''}`}
            onClick={() => raiseSort(column.path)}
          >
            {renderCell(column)}
            {showSortIcon && renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
