import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocationTypeOption } from 'utils/vodUtils';
import { HISTORY_STATES, HISTORY_FILTER } from 'constants/vod';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import Table from 'components/Table';
import Badge from 'components/Badge';
import ArrowRightIcon from 'remixicon-react/ArrowRightSLineIcon';
import ArrowLeftIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ActionIcon from 'remixicon-react/MoreFillIcon';
import RestartIcon from 'remixicon-react/RestartLineIcon';
import Loader from 'components/Loader';

const VodTable = ({
  onSelectEncode,
  currentState,
  onChangeState,
  page,
  onPageChange
}) => {
  const { t } = useTranslation(['common', 'glossary', 'validation']);

  const { push } = useHistory();
  const { pathname } = useLocation();
  const { theme } = useSelector(({ user }) => user.settings);
  const { history, loading_history, loading_job } = useSelector(
    ({ vod }) => vod
  );
  const pageSizes = [5, 10, 15, 20];
  const url = `${process.env.REACT_APP_COCKPIT_CDN}/icons`;

  const columns = [
    {
      path: 'name',
      label: t('validation:encode_name'),
      content: encode => (
        <Button
          variant='link'
          className='link color-high text-start shadow-none ps-0'
          onClick={() => onSelectEncode(encode)}
        >
          {encode.name}
        </Button>
      )
    },
    {
      path: 'state',
      label: t('glossary:state'),
      content: ({ state }) => (
        <Badge.Fill type={state}>{t(`glossary:${state}`)}</Badge.Fill>
      )
    },
    {
      path: 'output_type',
      label: t('glossary:output_type'),
      content: ({ output_type }) => {
        const type = getLocationTypeOption(output_type);
        if (!type) return '-';
        return <Badge.Fill type='destination'>{type}</Badge.Fill>;
      }
    },
    { path: 'last_updated', label: t('glossary:last_updated') },
    {
      key: 'action',
      content: encode => (
        <DropdownButton
          className='action-dropdown'
          title={<ActionIcon className='color-medium' />}
          renderMenuOnMount
        >
          <Dropdown.Item
            className='color-medium'
            onClick={() => push(`${pathname}/${encode.encode_uuid}`)}
          >
            <div className='icon-size'>
              <RestartIcon />
            </div>
            <h6 className='lh-base user-select-none my-auto mx-3'>
              {t('restart_encode')}
            </h6>
          </Dropdown.Item>
        </DropdownButton>
      )
    }
  ];
  return (
    <section className='tableBody-background rounded dp-01 position-relative'>
      {(loading_history || loading_job) && (
        <Loader className='position-absolute overlay-background w-100 h-100 rounded' />
      )}
      <div className='d-flex justify-content-between align-items-center px-4 py-3 underline'>
        <ButtonGroup aria-label='state-filter'>
          {HISTORY_FILTER.map(state => {
            const isAll = state === 'all';
            const isActive =
              JSON.stringify(currentState) ===
              JSON.stringify(isAll ? HISTORY_STATES : [state]);
            const invertTheme = theme => (theme === 'dark' ? 'light' : 'dark');
            const variant = isActive
              ? 'primary'
              : invertTheme(theme) + ' opacity-75';
            return (
              <Button
                key={state}
                variant={`outline-${variant}`}
                onClick={() => onChangeState(state)}
              >
                {t(`glossary:${state}`)}
              </Button>
            );
          })}
        </ButtonGroup>
        <div className='d-flex align-items-center gap-3'>
          <small className='color-medium m-0'>
            {t('glossary:rows_per_page')}
          </small>
          <Form.Select
            className='w-auto color-high pe-pointer border-line'
            aria-label='page-size'
            value={page['page_size']}
            onChange={({ target: { value } }) => onPageChange(parseInt(value))}
          >
            {pageSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <Table
        className='history-table underline m-0'
        data={history?.encodes}
        columns={columns}
        size='sm'
      />
      <div className='ps-2 pe-4 py-2 d-flex justify-content-between align-items-center'>
        <span className=''>
          <EmptyButton
            onClick={() => onPageChange('back')}
            disabled={page['page_count'] === 1}
          >
            <img src={`${url}/first-page-${theme}.svg`} alt='back' />
          </EmptyButton>
          <EmptyButton
            onClick={() => onPageChange('previous')}
            disabled={page['page_count'] === 1}
          >
            <ArrowLeftIcon />
          </EmptyButton>
          <EmptyButton
            onClick={() => onPageChange('next')}
            disabled={history?.encodes?.length < page['page_size']}
          >
            <ArrowRightIcon />
          </EmptyButton>
        </span>
        <small className='color-medium'>Page {page['page_count']}</small>
      </div>
    </section>
  );
};

export default VodTable;
