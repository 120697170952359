import i18n from 'config/i18next';
import { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import {
  LoginPage,
  StartPage,
  LivePage,
  VodPage,
  StartEncodePage,
  RestartEncodePage,
  SettingsPage,
  LiveFormPage,
  VodFormPage,
  SnapshotPage,
  NotFoundPage,
  ErrorBoundary
} from 'pages';
import Layout from 'components/Layout';
import ProtectedRoute from 'components/ProtectedRoute';
import Logout from 'components/Logout';
import Loader from 'components/Loader';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const language = ':lng(en|zh)';
  const type = ':type(encoder|source|destination)';
  const encodeType = ':encodeType(new|template|encode_config)';
  const action = ':action(new|edit|duplicate)';
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader fullscreen />}>
        <Layout>
          <ToastContainer autoClose={3000} transition={Slide} />
          <Switch>
            <Route path={`/${language}/login`} component={LoginPage} exact />
            <Route path='/logout' component={Logout} />
            <ProtectedRoute path={`/${language}/start`} component={StartPage} exact />
            <ProtectedRoute path={`/${language}/live`} component={LivePage} exact />
            <ProtectedRoute path={`/${language}/vod`} component={VodPage} exact />
            <ProtectedRoute
              path={`/${language}/vod/${encodeType}`}
              component={StartEncodePage}
              exact
            />
            <ProtectedRoute
              path={`/${language}/vod/:encode_uuid`}
              component={RestartEncodePage}
              exact
            />
            {/* <ProtectedRoute path={`/${lng}/usage`} component={UsagePage} exact /> */}
            <ProtectedRoute
              path={`/${language}/settings/:platform(live|vod)?`}
              component={SettingsPage}
              exact
            />
            <ProtectedRoute
              path={`/${language}/settings/:platform(live)/:uuid?/${action}`}
              component={LiveFormPage}
            />
            <ProtectedRoute
              path={`/${language}/settings/:platform(vod)/${type}/:uuid?/${action}`}
              component={VodFormPage}
            />
            {/* <ProtectedRoute path={`/${lng}/faq`} component={FaqPage} exact /> */}
            <ProtectedRoute
              path={`/snapshot/:uuid/:stack_uuid/:index`}
              component={SnapshotPage}
              exact
            />
            <Route path='/not-found' component={NotFoundPage} />
            <Redirect from='/' to={`/${i18n.language}/login`} exact />
            <Redirect to='/not-found' />
          </Switch>
        </Layout>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
